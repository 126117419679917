import React, { useEffect } from "react";
import { AuthContext } from "../utils/contexts/AuthContext";
import { AdminApis } from "../utils/Apis/Apis";
import TableTemplate from "./parts/TableTemplate";
import { LeadstudentColumns } from "../utils/Extras/Columns.js";
import AddLeads from "./Leads/AddLead.js";

const Leads = () => {
  const { AppFunctions, appStates } = React.useContext(AuthContext);

  useEffect(() => {
    if (appStates.leadsList && appStates.leadsList.length <= 0) {
      AppFunctions.getData({
        listType: "leadsList",
        api: AdminApis.getLeads,
      });
    }
  }, []);

  return (
    <div>
      {/* <h1>Hello world</h1> */}
      <TableTemplate
        data={appStates.leadsList}
        columns={LeadstudentColumns}
        Add_data={AddLeads}
      />
    </div>
  );
};

export default Leads;
