import React, { useState } from "react";
import { Grid } from "@chakra-ui/react";
import { GridItem } from "@chakra-ui/react";
import { FormLabel, Stack } from "@chakra-ui/react";
import { Radio, RadioGroup } from "@chakra-ui/react";
import { AuthContext } from "../../utils/contexts/AuthContext";
import { useToast } from "@chakra-ui/react";
import Gardians from "./Gardians";
import {
  Box,
  Button,
  Input,
  Select,
  Textarea,
  Flex,
  IconButton,
  InputGroup,
  InputRightElement,
  //   Chat,
  //   ChatMessage,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerOverlay,
  DrawerFooter,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { ChatIcon } from "@chakra-ui/icons";
import { FiPlus } from "react-icons/fi";
import { send_post } from "../../utils/hooks/AxiosHooks";
import { AdminApis, logsApi } from "../../utils/Apis/Apis";
// import { calcLength } from "framer-motion";

export default function AddLeads() {
  const toast = useToast();
  var mainThemeColor = "purple";

  // const [leads, setLeads] = useState([]);

  const { AppFunctions, appStates, theme } =
    React.useContext(AuthContext);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: ThisOpen,
    onOpen: ThatOpen,
    onClose: ThisClose,
  } = useDisclosure();

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  // const [chatOpen, setChatOpen] = useState(false);
  // const [chatMessages, setChatMessages] = useState([]);
  // const [newMessage, setNewMessage] = useState("");
  const [size, setSize] = React.useState("");

  // const onSubmit = async (data) => {
  //   // Handle form submission here
  //   console.log(data);
  // };

  // const handleChatIconClick = () => {
  //   setChatOpen(!chatOpen);
  // };

  // const handleChatMessageSubmit = () => {
  //   if (newMessage.trim() !== "") {
  //     setChatMessages([...chatMessages, newMessage]);
  //     setNewMessage("");
  //   }
  // };

  const onSubmit = (data) => {
    // const formData = new FormData();
    // formData.append("coursedata", JSON.stringify(data));

    send_post(AdminApis.addLead, data)
      .then((res) => {
        var response = res.data;
        console.log(response); //Here is handle the respone data from the server

        if (response.data.acknowledged) {
          toast({
            title: response.message,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }

        if (res.status === 200) {
          // add lead data
          reset();
          onClose();
        }
      })
      .catch((err) => {
        send_post(logsApi.senderror, {
          error: err,
        });
      });
  };

  const handleClick = (newSize) => {
    setSize(newSize);
    onOpen();
  };
  const sizes = ["xl"];

  return (
    <>
      {sizes.map((size) => (
        <Button
          rightIcon={<FiPlus />}
          onClick={() => {
            ThatOpen();
            handleClick(size);
          }}
          colorScheme="blue"
        >
          Add
        </Button>
      ))}

      <Drawer isOpen={ThisOpen} onClose={ThisClose} size={size}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerHeader color={"#8a148a"}>Add New Lead</DrawerHeader>

          <DrawerBody>
            <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
              <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                <GridItem
                  width={"100%"}
                  display={"grid"}
                  // gap={"10px"}
                >
                  <Input
                    {...register("student_name", { required: true })}
                    type="text"
                    placeholder="Name"
                  />
                </GridItem>
                <GridItem
                  width={"100%"}
                  display={"grid"}
                  // gap={"10px"}
                >
                  <Input
                    {...register("mobile_no", { required: true })}
                    type="tel"
                    placeholder="Mobile No."
                  />
                </GridItem>
                <GridItem
                  width={"100%"}
                  display={"grid"}
                  // gap={"10px"}
                >
                  <Input
                    {...register("student_emailId")}
                    type="email"
                    placeholder="Email ID"
                  />
                </GridItem>
                <GridItem width={"100%"} display={"grid"}>
                  <Input
                    {...register("Address")}
                    type="text"
                    name="Address"
                    placeholder="Address"
                    // ref={register({ required: true })}
                    // mb={4}
                  />
                </GridItem>
                <GridItem
                  width={"100%"}
                  display={"grid"}
                  // gap={"10px"}
                >
                  <RadioGroup defaultValue="male" w="100%" my="">
                    <Stack spacing={8} direction="row">
                      <Radio
                        size="md"
                        {...register("gender")}
                        colorScheme={mainThemeColor}
                        value="male"
                      >
                        Male
                      </Radio>
                      <Radio
                        size="md"
                        {...register("gender")}
                        name="gender"
                        colorScheme="green"
                        value="female"
                      >
                        Female
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </GridItem>
              </Grid>
              <Box mt={5}>
                <Gardians />
              </Box>
              <Box
                width={"100%"}
                mt={5}
                border={"0.5px solid lightgray"}
              ></Box>

              {/* Basic Info complete */}

              {/* Start Inquery section */}
              <Box
                mt={2}
                style={{
                  fontSize: "21px",
                  fontWeight: "600",
                  color: "#8a148a",
                }}
              >
                Course Inquery
              </Box>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                paddingTop={"20px"}
              >
                <GridItem
                  width={"100%"}
                  display={"grid"}
                  // gap={"10px"}
                >
                  <Select
                    {...register("courseInquiry")}
                    name="courseInquiry"
                    placeholder="Select Course"
                    // ref={register({ required: true })}
                    // mb={4}
                  >
                    <option value="Front End Developer">
                      Front End Developer
                    </option>
                    <option value="Mobile App Front End ( React Native & Liabraries )">
                      Mobile App Front End ( React Native & Liabraries
                      )
                    </option>
                    <option value="English Speaking">
                      English Speaking
                    </option>
                    <option value="Back End & Api Development Course">
                      Back End & Api Development Course
                    </option>
                    <option value="Computer Basics & Advance">
                      Computer Basics & Advance
                    </option>
                    <option value="Digital Marketing">
                      Digital Marketing
                    </option>
                    <option value="Full Stack Web Development">
                      Full Stack Web Development
                    </option>
                    <option value="  Spoken English Crash Course">
                      Spoken English Crash Course
                    </option>
                    <option value=" Computer Basics & Advance">
                      Computer Basics & Advance
                    </option>
                    <option value=" Full Stack Mobile App Development ( Frontend + Backend">
                      Full Stack Mobile App Development ( Frontend +
                      Backend )
                    </option>
                    {/* <option value="course1">Course 1</option>
                    <option value="course2">Course 2</option>
                    Add more course options here */}
                  </Select>
                </GridItem>
                <GridItem
                  width={"100%"}
                  display={"grid"}
                  // gap={"10px"}
                >
                  <Input
                    {...register("AttendCall")}
                    type="text"
                    name="AttendCall"
                    placeholder="Who Attended Call"
                    // ref={register({ required: true })}
                    // mb={4}
                  />
                </GridItem>
                <GridItem
                  width={"100%"}
                  display={"grid"}
                  // gap={"10px"}
                >
                  <Input
                    {...register("AttendingCall")}
                    type="text"
                    name="AttendingCall"
                    placeholder="Who Attending Now"
                    // ref={register({ required: true })}
                    // mb={4}
                  />
                </GridItem>
                <GridItem
                  width={"100%"}
                  display={"grid"}
                  // gap={"10px"}
                >
                  <Input
                    {...register("inqueryStatus")}
                    type="text"
                    name="inqueryStatus"
                    placeholder="Inquery Status"
                    // ref={register({ required: true })}
                    // mb={4}
                  />
                </GridItem>
              </Grid>

              <Textarea
                {...register("inquiry")}
                name="inquiry"
                placeholder="Inquiry"
                // ref={register({ required: true })}
                mb={4}
                mt={6}
              />

              {/* <Flex align="center">
                <IconButton
                  icon={<ChatIcon />}
                  aria-label="Chat"
                  onClick={handleChatIconClick}
                  mb={4}
                />
                <InputGroup ml={4} flex={1}>
                  <Input
                    type="text"
                    placeholder="Type your message..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    disabled={!chatOpen}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={handleChatMessageSubmit}
                      disabled={!chatOpen}
                    >
                      Send
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Flex> */}
              {/* {chatOpen && (
                <Box mt={4}>
                  <Chat>
                    {chatMessages.map((message, index) => (
                      <ChatMessage key={index} isMine>
                        {message}
                      </ChatMessage>
                    ))}
                  </Chat>
                </Box>
              )} */}
            </form>
          </DrawerBody>
          <DrawerFooter zIndex={-12}>
            <Button
              type="submit"
              colorScheme={mainThemeColor}
              minW={"250px"}
              form="my-form"
              onClick={ThisClose}
            >
              Add Lead
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
