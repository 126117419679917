import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../utils/contexts/AuthContext";
import { AdminApis, coursesApi } from "../utils/Apis/Apis";
import TableTemplate from "./parts/TableTemplate";
import {
  Checkbox,
  Box,
  SimpleGrid,
  HStack,
  VStack,
  Button,
  Stack,
  Image,
  Badge,
  Divider,
  IconButton,
  Text,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Select,
  Grid,
  GridItem,
  Avatar,
  Wrap,
  WrapItem,
  useToast,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Textarea,
  Center,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { FiEdit, FiMessageCircle, FiUser } from "react-icons/fi";
import { Card, CardBody, CardFooter } from "@chakra-ui/react";
import { send_delete } from "../utils/hooks/AxiosHooks";

const CoursesList = () => {
  const { AppFunctions, appStates } = useContext(AuthContext);
  // const [selectedPic, setSelectedPic] = useState(null);
  // const { isOpen, onOpen, onClose } = useDisclosure();
  // const [size, setSize] = React.useState("");
  // const toast = useToast();

  useEffect(() => {
    if (!appStates.CoursesList || appStates.CoursesList.length <= 0) {
      AppFunctions.getData({
        listType: "CoursesList",
        api: coursesApi.getCourses,
      });
    }
  }, []);

  // const handleCourseDelete = async (id) => {
  //   try {
  //     await send_delete(`${AdminApis.deleteCourse}/${id}`);
  //     AppFunctions.getData({
  //       listType: "CoursesList",
  //       api: coursesApi.getCourses,
  //     });
  //     toast({
  //       title: "Course deleted.",
  //       description: "The course has been successfully deleted.",
  //       status: "success",
  //       duration: 1500,
  //       isClosable: true,
  //     });
  //   } catch (err) {
  //     console.log("Something went wrong", err);
  //     toast({
  //       title: "Error deleting course.",
  //       description: "There was an error deleting the course.",
  //       status: "error",
  //       duration: 1500,
  //       isClosable: true,
  //     });
  //   }
  // };

  return (
    <>
      <div>
        <Center
          style={{ marginTop: 10, fontSize: 20, fontWeight: "bold" }}
        >
          All Batches starts in 9.30 am to 5 pm
        </Center>
        <SimpleGrid mt={4} columns={{ sm: 1, md: 4 }} spacing={4}>
          {appStates.CoursesList.map((course, index) => (
            <Card maxW="sm" m="0" p="0" key={index}>
              <CardBody p="0">
                <Image
                  src={course.CoverImageUrl}
                  alt={course.course}
                  borderRadius="lg"
                  borderBottomLeftRadius={0}
                  borderBottomRightRadius={0}
                />
                <Center m={5}>Start 9.30 to 11 pm</Center>
              </CardBody>
              <Divider />
              <CardFooter>
                <Stack
                  direction="column"
                  w="100%"
                  spacing={2}
                  align="center"
                >
                  <Text fontSize={"sm"}>{course.Course}</Text>
                  <Button
                    variant={"solid"}
                    colorScheme="teal"
                    w="100%"
                    size={"sm"}
                    rightIcon={<FiMessageCircle />}
                  >
                    Set Campaign
                  </Button>
                  <Button
                    colorScheme="green"
                    size={"sm"}
                    w="100%"
                    rightIcon={<FiUser />}
                  >
                    Enroll Student
                  </Button>
                </Stack>
              </CardFooter>
            </Card>
          ))}
        </SimpleGrid>
      </div>
    </>
  );
};

export default CoursesList;
