import React from "react";
import {
  SimpleGrid,
  Box,
  Card,
  CardBody,
  Text,
  CardHeader,
} from "@chakra-ui/react";

// Passing `columns={[2, null, 3]}` and `columns={{sm: 2, md: 3}}`
// will have the same effect.
export default function Dashboard() {
  return (
    <div>
      <SimpleGrid
        spacing="10px"
        columns={{ sm: 1, md: 2 }}
        // bg="#fff000"
        sx={{ paddingY: 4 }}
      // columns={[2, null, 3]}
      >
        <Box height="">
          <Card>
            <CardHeader> Studens Reports </CardHeader>
            <CardBody>
              <Text>Select Date</Text>
              <Text>Active Leads</Text>
            <Text>  Current Students ( On demo , Admissioned )</Text>

            </CardBody>
          </Card>
        </Box>
        <Box height="">
          <Card>
            <CardHeader>Courses</CardHeader>
            <CardBody>

              <Text>Courses Occupied</Text>
              <Text>Courses Leads</Text>
              <Text>Upcoming Fee</Text>
              <Text>Collected Fee</Text>
              <Text>Due Fee</Text>
            </CardBody>
          </Card>
        </Box>


        <Box height=''>
          <Card>
            <CardHeader>Team Report</CardHeader>
            <CardBody>
              <Text>   Teachers Report ( How Many Students Handling How much Time Occupied )</Text>
            </CardBody>
          </Card>
        </Box>

        <Box height=''>
          <Card>
            <CardHeader>Finance</CardHeader>
            <CardBody>

              <Text> Upcoming Expense </Text>
              <Text>Balance Need / Balance Surpluse</Text>
            </CardBody>
          </Card>
        </Box>
      </SimpleGrid>
    </div>
  );
}
