import React, { useState, useEffect } from "react";
import { Button } from "@chakra-ui/react";
import { Grid } from "@chakra-ui/react";
import { GridItem } from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import { Select } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
// import { Switch } from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/react";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { SmallAddIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import { AdminApis } from "../../utils/Apis/Apis";
import {
  send_delete,
  send_get,
  send_post,
} from "../../utils/hooks/AxiosHooks";

export default function FollowUp() {
  const { id } = useParams();
  // add form data
  const obj = {
    channel: "",
    studentName: "",
    name: "",
    textarea: "",
    date: "",
    leadId: id,
  };
  const [formData, setFormData] = useState(obj);
  // const [submitData, setSubmitData] = useState([]);

  const {
    isOpen: open,
    onOpen: thisopen,
    onClose: thisclose,
  } = useDisclosure();

  const [followUpsList, setFollowUpsList] = useState([]);

  const fetchFollowUps = async () => {
    try {
      const response = await send_get(
        `${AdminApis.getFollowUp}/${id}`
      );
      // console.log(response);
      setFollowUpsList(response.data.data);
    } catch (error) {
      console.log("Error fetching follow-up data:", error);
      toast({
        title: "Error fetching data",
        description: "Unable to fetch fee details",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchFollowUps();
  }, [id]);
  // Add FolloUp data

  const addFollowUp = async (data) => {
    send_post(`${AdminApis.addFollowUp}`, data)
      .then((res) => {
        fetchFollowUps();
        return res.data;
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => {
        console.log("final");
        thisclose();
      });
  };

  const deleteFollowup = async (data) => {
    var confirmation = window.confirm(
      "Are you sure, you want to Delete"
    );
    if (!confirmation) {
      return;
    }

    var id = data._id;
    console.log(id);

    send_delete(`${AdminApis.deleteFollowUp}/${id}`)
      .then((res) => {
        fetchFollowUps();
      })
      .catch((err) => {
        console.log("catch", err.message);
      });
  };

  // --------------------------------------------
  const handleInputChanges = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newFollowUp = await addFollowUp(formData);
      setFormData(obj);
      toast({
        title: "Inquiry Saved",
        status: "success",
        duration: 1000,
      });
    } catch (error) {
      console.log(error, "cannot find the data");
    }
  };

  const deleteref = React.useRef();

  // ---------------------------------------------
  const [size, setSize] = React.useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClick = (newSize) => {
    setSize(newSize);
    onOpen();
  };
  const sizes = ["xl"];
  // show alert
  const toast = useToast();

  // Remove leads
  // ---------------------------------------------------------------------
  const cancelRef = React.useRef();

  return (
    <>
      <Box style={{ position: "relative", margin: 50 }}>
        {sizes.map((size) => (
          <Button
            style={{
              position: "fixed",
              right: 80,
              bottom: 80,
              borderRadius: 50,
              height: 50,
              width: 50,
              background: "teal",
            }}
            onClick={() => handleClick(size)}
            key={size}
            m={4}
          >
            <SmallAddIcon style={{ fontSize: 30, color: "white" }} />
          </Button>
        ))}
        <Table variant="striped" colorScheme="gray">
          <Thead style={{ background: "grey" }}>
            <Tr>
              <Th style={{ color: "white" }}>Instrumentality</Th>
              <Th style={{ color: "white" }}>Student Name</Th>
              <Th style={{ color: "white" }}>Call</Th>
              <Th style={{ color: "white" }}>Date/Time</Th>
              <Th style={{ color: "white" }}>Remarks</Th>
              <Th style={{ color: "white" }}>Delete FolloUp</Th>
            </Tr>
          </Thead>
          <Tbody>
            {followUpsList.map((data, index) => {
              // console.log(data);
              return (
                <Tr key={index}>
                  <Td>{data.channel}</Td>
                  <Td>{data.studentName}</Td>
                  <Td>{data.name}</Td>
                  <Td>{data.date}</Td>
                  <Td>{data.textarea}</Td>
                  <Td>
                    <Button
                      key={index}
                      colorScheme="red"
                      // onClick={thisopen}
                      onClick={() => deleteFollowup(data)}
                    >
                      <DeleteIcon />
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>

      <Drawer onClose={onClose} isOpen={isOpen} size={size}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            display={"flex"}
            justifyContent={"space-between"}
          >
            <FormLabel fontWeight={600} fontSize="24px">
              Follow-up Details
            </FormLabel>
          </DrawerHeader>

          <DrawerBody
            css={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <form onSubmit={handleSubmit}>
              <FormControl>
                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                  <GridItem
                    alignItems={"center"}
                    width={"100%"}
                    display={"grid"}
                  >
                    <Select
                      name="channel"
                      value={formData.channel}
                      onChange={handleInputChanges}
                      placeholder="Select channel"
                    >
                      <option value="Whatsapp">Whatsapp</option>
                      <option value="SMS">SMS</option>
                      <option value="Call">Call</option>
                    </Select>
                  </GridItem>
                  <GridItem width={"100%"} display={"grid"}>
                    <Input
                      name="studentName"
                      value={formData.studentName}
                      onChange={handleInputChanges}
                      placeholder="Student name"
                    />
                  </GridItem>
                  <GridItem width={"100%"} display={"grid"}>
                    <Input
                      name="name"
                      value={formData.name}
                      onChange={handleInputChanges}
                      placeholder="Who Called"
                    />
                  </GridItem>
                  <GridItem width={"100%"} display={"grid"}>
                    <Input
                      type="date"
                      name="date"
                      value={formData.date}
                      onChange={handleInputChanges}
                      variant="outline"
                      placeholder="Date"
                    />
                  </GridItem>
                </Grid>

                <Box>
                  <Grid
                    templateColumns="repeat(2, 1fr)"
                    gap={4}
                  ></Grid>
                  <Box mt={6}>
                    <Textarea
                      name="textarea"
                      value={formData.textarea}
                      onChange={handleInputChanges}
                      placeholder="Inquiry"
                    />
                  </Box>

                  <Box mt={6} display={"grid"} gap={"10px"}>
                    <Button type="submit" colorScheme="blue">
                      Save
                    </Button>
                  </Box>
                </Box>
              </FormControl>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <AlertDialog
        isOpen={open}
        leastDestructiveRef={deleteref}
        onClose={thisclose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="700">
              Delete Details
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You want to delete Details.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={thisclose}>
                Cancel
              </Button>
              <Button
                onChange={thisclose}
                colorScheme="red"
                onClick={() => {
                  // deleteFollowup(data._id, index);
                  thisclose();
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
