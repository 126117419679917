import React, { useState, useEffect } from 'react';
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import { Box, HStack, IconButton, Input, Select, Button, useColorMode, useColorModeValue, Image } from '@chakra-ui/react';
import { FiEdit, FiTrash2, FiChevronLeft, FiChevronRight, FiPlus } from 'react-icons/fi';
import { Avatar, TableContainer, Table, TableCaption, Thead, Tr, Th, Td, Tbody, Tfoot } from "@chakra-ui/react"
// import AddStudent from "./../StudentsList/AddStudent.js"
import { Link, useNavigate } from 'react-router-dom';
import { Controls, Player } from '@lottiefiles/react-lottie-player';





const TableTemplate = ({ data, columns, Add_data  }) => {

    // console.log(data , columns)


    var navigate = useNavigate()
    const [pageSize, setPageSize] = React.useState(10); // Initial page size

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        pageCount,
        state: { pageIndex },
        gotoPage,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        state,
        setGlobalFilter, // Function to set the global filter value
        setPageSize: setTablePageSize, // Rename to avoid conflict with local state variable

    } = useTable(
        {
            columns,
            // data: userData,
            data,
            initialState: { pageIndex: 0, pageSize:10 },
        },
        useGlobalFilter, // Hook for global filtering
        useSortBy,
        usePagination,

    );

    const { globalFilter } = state; // Access the global filter value

    // Event handler for the search input
    const handleSearch = (e) => {
        const value = e.target.value || ''; // Get the search input value
        setGlobalFilter(value); // Update the global filter value
    };

    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value);
        setPageSize(newSize);
        setTablePageSize(newSize);
    };

    // console.log(page)
    // console.log(pageCount)

    return (
        <>
            <Box overflowX="scroll" maxH={"75vh"}>

                <HStack pt="3" pb="3" px="4" bg={useColorModeValue("#fff", "#171923")} direction={['row', 'column']} justifyContent={"space-between"} alignItems={"center"}   >
                    <Box>


                        <Input
                            type="text"
                            placeholder="Search..."
                            value={globalFilter} // Bind the value of the search input to the global filter value
                            onChange={handleSearch} // Call the handleSearch function on input change
                        />
                    </Box>

                    {/* <Button rightIcon={<FiPlus />} colorScheme='blue'  >
              Add
            </Button> */}
            
                <Add_data />
            

                </HStack>


<TableContainer>



                <Table  bg={useColorModeValue('#fff', '#171923')} {...getTableProps()} style={{ width: '100%' }}>

                    {/* <Thead > */}
                    <Thead position="sticky" top="0" bg={useColorModeValue('#fff', '#171923')} zIndex="99">

                        {headerGroups.map((headerGroup) => (
                            <Tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                        </span>
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody overflow={"scroll"} maxW={"max-content"} {...getTableBodyProps()}>
                        {
                            page.length === 0 ? (
                                <Tr>
                                    <Td colSpan={columns.length}>
                                        <Player src={require("../../Assets/lottie/nodata.json")}
                                            style={{ height: '400px', width: 'auto' }}
                                            // background='red'
                                loop
                                            autoplay
                                        >
                                            {/* <Controls
                                                visible={true}
                                                darkTheme={true}
                                                buttons={['play', 'repeat', 'frame', 'stop', 'background']}
                                            /> */}
                                        </Player>
                                        {/* <Image src="/path/to/image.png" alt="No data" /> */}
                                    </Td>
                                </Tr>
                            )
                                : (
                                    page.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <Tr {...row.getRowProps()}>

                                                {
                                                    row.cells.map((cell) => (
                                                        <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                                                    ))
                                                }
                                            </Tr>
                                        );
                                    })
                                )
                        }
                    </Tbody>
                </Table>
                </TableContainer>
            </Box>
            <HStack pt="3" pb="3" px="4" bg={useColorModeValue('#fff', '#171923')} direction={['row']} justifyContent={"space-between"} alignItems={"center"} >


                <div>


                    <span>
                        Records{' '}
                        <strong>
                            {pageIndex * page.length} - {(pageIndex + 1) * page.length} of {page.length * pageCount}
                        </strong>{' '}
                    </span>
                </div>





                <HStack>

                    <IconButton
                        colorScheme={canPreviousPage ? 'green' : 'gray'}
                        aria-label="Previous Page"
                        icon={<FiChevronLeft />}
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    />
                    {/* <Box> */}


                    {/* <Input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(pageNumber);
              }}
              style={{ width: '50px', textAlign: 'center', margin: '0 5px' }}
            /> */}

                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageCount}
                        </strong>{' '}
                    </span>
                    <IconButton
                        colorScheme={canNextPage ? 'green' : 'gray'}
                        aria-label="Next Page"
                        icon={<FiChevronRight />}
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                    />

                </HStack>
                <Box>

                    <Select value={pageSize} onChange={handlePageSizeChange}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </Select>
                </Box>

            </HStack>
        </>
    );
};


export default TableTemplate;