import { Button, HStack, Heading, Image, VStack } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function ComingSoon() {

  const navigate = useNavigate();

  return (
    <VStack 
      h="100vh" 
      justifyContent={"center"} 
      bgGradient="linear(62deg, #8EC5FC 0%, #E0C3FC 100%)" 
      px={{ base: 4, md: 8 }} // padding for different screen sizes
    >

      <Image 
        src={require("../Assets/Images/logo/logo2.png")} 
        h={{ base: "80px", md: "100px", lg: "120px" }} // Responsive height for image
        mb={4} // margin-bottom
      />

      <Heading 
        size={{ base: "lg", md: "xl" }}  // Responsive font size
        color={"white"} 
        textAlign="center" // Align text to center
        mb={2}
      >
        We Are Cooking Something Special For HomePage
      </Heading>

      <Heading 
        size={{ base: "lg", md: "xl" }}  
        color={"white"} 
        textAlign="center" 
        mb={8}
      >
        Will Activate HomePage Soon
      </Heading>

      <HStack spacing={4} justifyContent="center" flexWrap="wrap">
        <Button 
          colorScheme='green' 
          onClick={() => navigate("/myPortal")} 
          size={{ base: "sm", md: "md" }} // Responsive button size
        >
          Student Login
        </Button>

        <Button 
          colorScheme='blue' 
          onClick={() => navigate("/login")} 
          size={{ base: "sm", md: "md" }}
        >
          Admin Login
        </Button>

        <Button 
          colorScheme='pink' 
          onClick={() => navigate("/verify")} 
          size={{ base: "sm", md: "md" }}
        >
          Verify Certificate  
        </Button>
      </HStack>
    </VStack>
  )
}
