import React, { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";

import {
  Box,
  Textarea,
  Button,
  useDisclosure,
  Grid,
  GridItem,
  Select,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Text,
} from "@chakra-ui/react";
import { SmallAddIcon, DeleteIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";
import { AdminApis } from "../utils/Apis/Apis";
import CreatableSelect from "react-select/creatable";
import {
  send_get,
  send_post,
  send_delete,
} from "../utils/hooks/AxiosHooks";
import { useParams } from "react-router-dom";
import { FormLabel } from "react-bootstrap";

export default function DoAdmission() {
  const [size, setSize] = React.useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const toast = useToast();

  const handleClick = (newSize) => {
    setSize(newSize);
    onOpen();
  };

  const { admissionid } = useParams();
  const { register, handleSubmit, reset, setValue, watch } =
    useForm();
  const [submission, setSubmission] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [Batches, setBatches] = useState([]);

  const [courseDuration, setCourseDuration] = useState(null);
  const [courseFee, setCourseFee] = useState(null);

  const [selectTime, setSelectTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const fetchAdmission = async () => {
    try {
      const response = await send_get(
        `${AdminApis.getAdmission}/${admissionid}`
      );
      setSubmission(response.data.data);
    } catch (error) {
      console.log("Error Fetching data", error);
    }
  };
  // batches
  const fetchBatches = async () => {
    try {
      const response = await send_get(`${AdminApis.getBatches}`);
      console.log(response.data.data);
      setBatches(response.data.data);
    } catch (error) {
      console.log("Error Fetching data", error);
    }
  };

  useEffect(() => {
    fetchAdmission();
    fetchBatches();
    send_get(`${AdminApis.getCourses}`).then((resp) => {
      setCourseData(resp.data.data);
      console.log(resp.data);
    });
  }, [admissionid]);
  // Automatic Course_Duration
  useEffect(() => {
    console.log(["working one", watch("course_id"), courseData]);
    const SelectCourse = courseData.find((e) => {
      return e._id === watch("course_id");
    });
    setCourseDuration(SelectCourse ? SelectCourse.Duration : null);
    console.log("working two", courseDuration);
  }, [watch("course_id")]);

  // Automatic Select courseFee
  useEffect(() => {
    const SelectFee = courseData.find((e) => {
      return e._id === watch("course_id");
    });
    setCourseFee(SelectFee ? SelectFee.fee : null);
  }, [watch("course_id")]);

  // Automatice start_Time Select
  useEffect(() => {
    const SelectBatch = Batches.find((e) => {
      return e._id === watch("batch_id");
    });
    setSelectTime(SelectBatch ? SelectBatch.startingTime : null);
  }, [watch("batch_id")]);

  // Automatic EndTime Select
  useEffect(() => {
    const SelectEndTime = Batches.find((e) => {
      return e._id === watch("batch_id");
    });
    setEndTime(SelectEndTime ? SelectEndTime.EndTime : null);
  }, [watch("batch_id")]);
  const onSubmit = async (data) => {
    try {
      data.student_id = admissionid;
      const response = await send_post(
        `${AdminApis.addAdmission}`,
        data
      );
      setSubmission((prevSubmissions) => [...prevSubmissions, data]);
      reset();
      onClose();

      toast({
        title: "Admission Added Successfully",

        status: "success",
        duration: 2000,
      });
    } catch (error) {
      console.log("Error submitting data", error);
      toast({
        title: "Error",
        description: "Unable to delete data",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (Id) => {
    try {
      await send_delete(`${AdminApis.deleteAdmission}/${Id}`);
      setSubmission((prevSubmissions) =>
        prevSubmissions.filter((sub) => sub.id !== Id)
      );
      toast({
        title: "Admission Deleted Successfully",

        duration: 2000,
        status: "success",
      });
      console.log("Admission Delete sucessfully");
    } catch (error) {
      console.log("Error deleting data", error);
      toast({
        title: "Error",
        description: "Unable to delete data",
        status: "error",
        duration: 2000,
      });
    }
  };

  const referenceOptions = [
    {
      label: "Events",
      options: [
        { label: "Event Sodhapur", value: "Event 1" },
        { label: "Event Shiv Nagar", value: "Event 2" },
        { label: "Event Model Town", value: "Event 3" },
        { label: "Event KhotPur", value: "Event 4" },
      ],
    },
    {
      label: "Partners",
      options: [
        { label: "Kalayani Education Group", value: "Deepak" },
        { label: "Balvinder ", value: "Deepak" },
        { label: "Deepak", value: "Deepak" },
      ],
    },
  ];

  const handleReferralSelect = (selectedOptions) => {
    const selectedNames = selectedOptions.map(
      (option) => option.label
    );
    setValue("refered_by", selectedNames);
  };

  return (
    <>
      <Box style={{ position: "relative", margin: 50 }}>
        <Button
          style={{
            position: "fixed",
            right: 80,
            bottom: 80,
            borderRadius: 50,
            height: 50,
            width: 50,
          }}
          ref={btnRef}
          colorScheme="teal"
          onClick={() => handleClick("lg")}
        >
          <SmallAddIcon style={{ fontSize: 30 }} />
        </Button>

        <TableContainer>
          <Table variant="striped">
            <Thead style={{ background: "grey", height: "60px" }}>
              <Tr>
                <Th style={{ color: "white" }}>Course</Th>
                <Th style={{ color: "white" }}>Duration</Th>
                <Th style={{ color: "white" }}>Date</Th>
                <Th style={{ color: "white" }}>Course Fee</Th>
                <Th style={{ color: "white" }}>Delete</Th>
              </Tr>
            </Thead>
            <Tbody>
              {submission.map((submission, index) => (
                <Tr key={index}>
                  <Td>{submission.course_id}</Td>
                  <Td>{submission.course_duration}</Td>
                  <Td>{submission.admission_date}</Td>
                  <Td>{submission.total_fee}</Td>
                  <Td>
                    <Button
                      onClick={() => handleDelete(submission._id)}
                      style={{ background: "red", color: "white" }}
                    >
                      <DeleteIcon />
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size={size}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Select your course</DrawerHeader>
          <DrawerBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                <GridItem>
                  <Select
                    placeholder="Select Course"
                    {...register("course_id", { required: true })}
                  >
                    {courseData.map((data) => (
                      <option key={data._id} value={data._id}>
                        {`${data.Course}`}
                      </option>
                    ))}
                  </Select>
                </GridItem>
                <GridItem>
                  <CreatableSelect
                    isMulti
                    options={referenceOptions}
                    onChange={handleReferralSelect}
                    placeholder="Referred by"
                  />
                </GridItem>
                <GridItem>
                  <Input
                    {...register("admission_date", {
                      required: true,
                    })}
                    type="date"
                    placeholder="Date"
                  />
                </GridItem>
                <GridItem>
                  <Input
                    {...register("course_duration", {
                      required: true,
                    })}
                    readOnly
                    value={courseDuration}
                    placeholder="Course Duration"
                  />
                </GridItem>
                <GridItem>
                  <Input
                    {...register("complition_target", {
                      required: true,
                    })}
                    placeholder="Course_Complition_target"
                  />
                </GridItem>
                <GridItem>
                  <Input
                    {...register("total_fee", { required: true })}
                    readOnly
                    value={courseFee}
                    placeholder="Course Fee"
                  />
                </GridItem>
                <GridItem>
                  <Input
                    {...register("applied_fee", { required: true })}
                    type="number"
                    placeholder="Applied_Fee"
                  />
                </GridItem>
                <GridItem>
                  <Input
                    {...register("fee_reason", { required: true })}
                    type="text"
                    placeholder="Fee_reason"
                  />
                </GridItem>
              </Grid>
              <Box
                width={"100%"}
                mt={9}
                mb={5}
                border={"0.5px solid lightgray"}
              ></Box>

              <Text mt={5} mb={5} fontWeight={600} fontSize="20px">
                Select Batch
              </Text>
              <Grid>
                <GridItem>
                  <FormLabel>Batch_No</FormLabel>
                  <Select
                    style={{ marginBottom: 10, marginTop: 10 }}
                    {...register("batch_id", { required: true })}
                    type="text"
                    placeholder="Batch_Id"
                  >
                    {Batches.map((data) => (
                      <option key={data._id} value={data._id}>
                        {data.BatchNo}
                      </option>
                    ))}
                  </Select>
                </GridItem>
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                <GridItem>
                  <FormLabel>Starting_Time</FormLabel>
                  <Input
                    style={{ marginTop: 10 }}
                    {...register("class_starting_time", {
                      required: true,
                    })}
                    readOnly
                    value={selectTime}
                    placeholder="Start_Batch"
                  />
                </GridItem>

                <GridItem>
                  <FormLabel>End_Time</FormLabel>
                  <Input
                    {...register("class_end_time", {
                      required: true,
                    })}
                    style={{ marginTop: 10 }}
                    readOnly
                    value={endTime}
                    placeholder="End_Batch"
                  />
                </GridItem>
              </Grid>

              <Textarea
                style={{ marginTop: 10 }}
                {...register("remark", { required: true })}
                placeholder="remark"
              />

              <DrawerFooter>
                <Button variant="outline" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="blue" type="submit">
                  Save
                </Button>
              </DrawerFooter>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
