import React, { useState } from "react";
import {
  Box,
  HStack,
  IconButton,
  Input,
  Button,
  InputGroup,
  InputLeftAddon,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerOverlay,
  DrawerFooter,
  DrawerCloseButton,
  useDisclosure,
  SimpleGrid,
  VStack,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { FiTrash2, FiPlus } from "react-icons/fi";
import { useForm, useFieldArray } from "react-hook-form";
import { Avatar, AvatarBadge } from "@chakra-ui/react";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { Radio, RadioGroup } from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";
import "react-datepicker/dist/react-datepicker.css";
import { send_post } from "../../utils/hooks/AxiosHooks";
import { AdminApis, logsApi } from "../../utils/Apis/Apis";
import { AuthContext } from "../../utils/contexts/AuthContext";

export default function AddStudent() {
  const toast = useToast();

  const { AppFunctions, appStates, theme } =
    React.useContext(AuthContext);

  const { isOpen, onOpen, onClose } = useDisclosure();

  // const { register, handleSubmit, setValue } = useForm()
  const {
    register,
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const {
    fields: institutions,
    append: appendInstitution,
    remove: removeInstitution,
  } = useFieldArray({
    control,
    name: "previousInstitutions",
  });

  const {
    fields: guardians,
    append: appendGuardian,
    remove: removeGuardian,
  } = useFieldArray({
    control,
    name: "guardians",
  });

  const [acceptedFiles, setAcceptedFiles] = useState({
    profilePicture: null,
    documents: [],
  });

  const {
    getRootProps: getProfilePictureRootProps,
    getInputProps: getProfilePictureInputProps,
    isDragActive: isProfilePictureDragActive,
  } = useDropzone({
    accept: "image/*",
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setAcceptedFiles((prevState) => ({
        ...prevState,
        profilePicture: acceptedFiles[0],
      }));
    },
  });

  const {
    getRootProps: getDocumentRootProps,
    getInputProps: getDocumentInputProps,
    isDragActive: isDocumentDragActive,
  } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
      "image/*": [".png", ".jpg", ".jpeg", ".gif"],
    },
    maxFiles: 5,
    onDrop: (acceptedFiles) => {
      setAcceptedFiles((prevState) => ({
        ...prevState,
        documents: [...prevState.documents, ...acceptedFiles],
      }));
    },
  });

  // Function to remove a document from the state
  const removeDocument = (fileName) => {
    setAcceptedFiles((prevState) => ({
      ...prevState,
      documents: prevState.documents.filter(
        (doc) => doc.name !== fileName
      ),
    }));
  };

  const onSubmit = (data) => {
    // console.log(data); // Handle form submission
    const file = acceptedFiles[0];
    const formData = new FormData();

    if (acceptedFiles.profilePicture) {
      formData.append("image", acceptedFiles.profilePicture);
    }

    formData.append("image", file);
    formData.append("studentData", JSON.stringify(data));
    acceptedFiles.documents.forEach((doc) => {
      formData.append("documents", doc);
    });

    send_post(AdminApis.addStudent, formData)
      .then((res) => {
        var response = res.data;
        console.log(response); // Handle the response from the server
        // upload_student_profile_pic(response.data.studentId)
        if (response.data.acknowledged) {
          toast({
            title: response.message,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
        if (res.status === 200) {
          reset();
          onClose();
        }
        // window.location.reload();
      })
      .catch((err) => {
        send_post(logsApi.senderror, {
          error: err,
        });
      });

    // console.log(acceptedFiles[0])
  };

  const [startDate, setStartDate] = useState(new Date());

  var mainThemeColor = "purple";

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.data.__isNew__ ? "normal" : "bold",
      paddingRight: state.data.__isNew__ ? "10px" : "20px",
      color: state.data.__isNew__ ? "green" : "purple",
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontWeight: "bold",
      color: "red",
      fontSize: "15px",
    }),

    // option: (provided, state) => ({
    //     ...provided,
    //     fontWeight: state.data.__group ? 'bold' : 'normal',
    //   }),

    option: (provided, state) => ({
      ...provided,
      fontWeight: state.selectProps.options.find(
        (option) => option.label === state.label && !option.options
      )
        ? "bold"
        : "normal",
    }),
  };

  const refrenceOptions = [
    {
      label: "Events",
      options: [
        {
          label: "Event Sodhapur",
          value: "Event 1",
        },
        {
          label: "Event Shiv Nagar",
          value: "Event 2",
        },
        {
          label: "Event Model Town",
          value: "Event 3",
        },
        {
          label: "Event KhotPur",
          value: "Event 4",
        },
      ],
    },
    {
      label: "Partners",
      options: [
        { label: "Kalayani Education Group", value: "Deepak" },
        { label: "Balvinder ", value: "Deepak" },
        { label: "Deepak", value: "Deepak" },
      ],
    },
  ];

  const Qualifications = [
    {
      label: "Under Secondary",
      value: "Under Secondary",
    },
    {
      label: "Secondary",
      value: "Secondary",
    },
    {
      label: "Sr. Secondory ( 12th )",
      value: "Sr. Secondory ( 12th )",
    },
    {
      label: "Graduation Degree",
      value: "Graduation Degree",
    },
    {
      label: "Master's Degree",
      value: "Master's Degree",
    },
  ];

  const handleRefralSelect = (selectedOptions) => {
    const selectedNames = selectedOptions.map(
      (option) => option.label
    );
    setValue("refered_by", selectedNames);
  };

  const handleQualificationSelect = (selectedOptions) => {
    const selectedNames = selectedOptions.map(
      (option) => option.label
    );
    setValue("qualifications", selectedNames);
  };

  const handleGuardianInputChange = (value, guardianIndex, field) => {
    setValue(`guardians[${guardianIndex}].${field}`, value);
  };

  const handleInstitutionInputChange = (
    value,
    institutionIndex,
    field
  ) => {
    setValue(
      `previousInstitutions[${institutionIndex}].${field}`,
      value
    );
  };

  return (
    <>
      {/* <AddComponent  onClick={onOpen}> </AddComponent> */}
      <Button
        rightIcon={<FiPlus />}
        onClick={onOpen}
        colorScheme="blue"
      >
        Add
      </Button>

      <Drawer isOpen={isOpen} onClose={onClose} size={"xl"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerHeader color={mainThemeColor}>
            Add New Student
          </DrawerHeader>

          <DrawerBody>
            <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
              {/* Student Info Sectiom  */}

              <SimpleGrid
                spacing="10px"
                columns={{ sm: 1, md: 2 }}
                sx={{ paddingY: 4 }}
              >
                <Box height="" paddingRight={"4"}>
                  <VStack>
                    <Box
                      alignItems={"center"}
                      {...getProfilePictureRootProps()}
                      p={4}
                      borderWidth={2}
                      borderColor={
                        !isProfilePictureDragActive
                          ? mainThemeColor
                          : "green"
                      }
                      borderStyle="dashed"
                      borderRadius={"full"}
                      textAlign="center"
                      cursor="pointer"
                    >
                      <input {...getProfilePictureInputProps()} />
                      {isProfilePictureDragActive ? (
                        <p>Drop the files here...</p>
                      ) : (
                        <>
                          {acceptedFiles.profilePicture ? (
                            <>
                              <Avatar
                                size="2xl"
                                src={URL.createObjectURL(
                                  acceptedFiles.profilePicture
                                )}
                              >
                                <AvatarBadge
                                  name="Profile"
                                  size="xs"
                                  borderColor="papayawhip"
                                  bg="green"
                                  boxSize="1em"
                                />
                              </Avatar>
                            </>
                          ) : (
                            <Avatar size={"2xl"}>
                              <AvatarBadge
                                name="Profile"
                                size="xs"
                                borderColor="papayawhip"
                                bg="tomato"
                                boxSize="1em"
                              />
                            </Avatar>
                          )}
                        </>
                      )}
                    </Box>

                    <FormControl my="1.5">
                      <Input
                        {...register("student_name")}
                        type="text"
                        placeholder="Student Name"
                      />
                    </FormControl>

                    <RadioGroup defaultValue="male" w="100%" my="">
                      <Stack spacing={8} direction="row">
                        <Radio
                          size="md"
                          {...register("gender")}
                          name="gender"
                          colorScheme={mainThemeColor}
                          value="male"
                        >
                          Male
                        </Radio>
                        <Radio
                          size="md"
                          {...register("gender")}
                          name="gender"
                          colorScheme="green"
                          value="female"
                        >
                          Female
                        </Radio>
                      </Stack>
                    </RadioGroup>

                    <FormControl my="">
                      {/* <FormLabel>Aadhar card</FormLabel> */}
                      <Input
                        type="text"
                        {...register("aadhar_card_no")}
                        placeholder="Student Aadhar card"
                      />
                    </FormControl>

                    <FormControl my="">
                      {/* <FormLabel>Phone No</FormLabel> */}
                      <InputGroup>
                        <InputLeftAddon children="+91" />
                        <Input
                          type="tel"
                          {...register("mobile_no")}
                          placeholder="phone number"
                        />
                      </InputGroup>
                    </FormControl>
                  </VStack>
                </Box>

                <Box>
                  <Textarea
                    my="1.5"
                    {...register("student_address")}
                    placeholder="Student Address"
                  />

                  <FormControl my="1.5">
                    {/* <FormLabel>Date Of Birth</FormLabel> */}
                    <Input
                      type="Date"
                      {...register("student_dob")}
                      placeholder="Date of Birth"
                    />
                  </FormControl>

                  <FormControl my="2">
                    {/* <FormLabel>Email</FormLabel> */}
                    <Input
                      type="email"
                      {...register("student_emailId")}
                      placeholder="Email Id"
                    />
                  </FormControl>

                  <FormControl my="2">
                    <FormLabel>Qualifications</FormLabel>
                    <CreatableSelect
                      // {...register("student_qualification")}
                      // placeholder="Qualification"
                      isMulti
                      onChange={handleQualificationSelect}
                      options={Qualifications}
                      styles={customStyles}
                    />
                  </FormControl>

                  <FormControl my="1.5">
                    <FormLabel>Refered by</FormLabel>
                    <CreatableSelect
                      // {...register("refered_by")}
                      // placeholder="refered by"
                      isMulti
                      options={refrenceOptions}
                      onChange={handleRefralSelect}
                      styles={customStyles}
                    />
                  </FormControl>
                </Box>
              </SimpleGrid>

              {/* student info Section ends  */}
              <hr />

              {/* Gaurdian Section  */}

              <HStack
                my="2"
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <h6 size={"md"} direction="row">
                  Gaurdians Info
                </h6>
                <IconButton
                  colorScheme={mainThemeColor}
                  aria-label="Add"
                  icon={<FiPlus />}
                  size={"xs"}
                  onClick={() => {
                    appendGuardian({}); // Add a new guardian object to the array
                  }}
                />
              </HStack>

              {/* <SimpleGrid
                                my="2"
                                spacing='10px'
                                columns={{ sm: 1, md: 4 }}
                                sx={{ paddingY: 1 }}
                            >

                                <FormControl>
                                    <CreatableSelect 
                                        {...register(`guardians.test.relationship`)}
                                        name="test"
                                        options={[{ label: "Mother", value: "mother" }, { label: "Father", value: "father" }, { label: "Sister", value: "Sister" }]} 
                                    />
                                </FormControl>

                                <FormControl>
                                    <Input placeholder="Gaurdian Name" />
                                </FormControl>


                                <FormControl>
                                    <Input placeholder="Occupation" />
                                </FormControl>

                                <Stack direction={"row"}>

                                    <FormControl>
                                        <Input name="Mobile" placeholder="Mobile no." />
                                    </FormControl>
                                    <IconButton
                                        colorScheme='red'
                                        icon={<FiTrash2 />}
                                    />
                                </Stack>
                            </SimpleGrid> */}

              {guardians.map((guardian, guardianIndex) => (
                <SimpleGrid
                  bg=""
                  my="2"
                  spacing="10px"
                  columns={{ sm: 1, md: 4 }}
                  sx={{ paddingY: 1 }}
                >
                  {/* <Stack bg="green" w="100%" key={guardian.id} direction="row" alignItems="center" justifyContent={"center"}> */}
                  <FormControl>
                    <CreatableSelect
                      // {...register(`guardians.${guardianIndex}.relationship`)}
                      onChange={(value) =>
                        handleGuardianInputChange(
                          value.value,
                          guardianIndex,
                          "relationship"
                        )
                      }
                      // name={`guardians.${guardianIndex}.relationship`}
                      options={[
                        { label: "Mother", value: "mother" },
                        { label: "Father", value: "father" },
                        { label: "Sister", value: "sister" },
                      ]}
                    />
                  </FormControl>

                  <FormControl>
                    <Input
                      {...register(`guardians.${guardianIndex}.name`)}
                      placeholder="Guardian Name"
                    />
                  </FormControl>

                  <FormControl>
                    <Input
                      {...register(
                        `guardians.${guardianIndex}.occupation`
                      )}
                      placeholder="Occupation"
                    />
                  </FormControl>

                  <Stack direction="row" alignItems="center">
                    <FormControl>
                      <Input
                        {...register(
                          `guardians.${guardianIndex}.mobile`
                        )}
                        name={`guardians.${guardianIndex}.mobile`}
                        placeholder="Mobile no."
                      />
                    </FormControl>
                    <IconButton
                      colorScheme="red"
                      aria-label="Delete Guardian"
                      icon={<FiTrash2 />}
                      onClick={() => {
                        removeGuardian(guardianIndex); // Remove the guardian at the specified index
                      }}
                    />
                  </Stack>
                  {/* </Stack> */}
                </SimpleGrid>
              ))}

              {/* Gaurdian Section ends  */}
              <hr />
              {/* Previous Institutes section  */}

              <HStack
                my="2"
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <h6 size={"md"} direction="row">
                  Previous Institutes
                </h6>
                <IconButton
                  colorScheme={mainThemeColor}
                  aria-label="Delete"
                  icon={<FiPlus />}
                  size={"xs"}
                  onClick={() => {
                    appendInstitution({}); // Add a new institution object to the array
                  }}
                />
              </HStack>

              {institutions.map(
                (reqinstitution, institutionIndex) => (
                  <SimpleGrid
                    my="2"
                    spacing="10px"
                    columns={{ sm: 1, md: 4 }}
                    sx={{ paddingY: 1 }}
                  >
                    <FormControl>
                      <CreatableSelect
                        onChange={(value) =>
                          handleInstitutionInputChange(
                            value.value,
                            institutionIndex,
                            "name"
                          )
                        }
                        options={[
                          { label: "ICS", value: "ICS" },
                          { label: "Vidual", value: "Vidual" },
                          { label: "ITI", value: "ITI" },
                        ]}
                      />
                    </FormControl>

                    <FormControl>
                      <Input
                        {...register(
                          `previousInstitutions.${institutionIndex}.location`
                        )}
                        placeholder="Location"
                      />
                    </FormControl>

                    <FormControl>
                      <Input
                        {...register(
                          `previousInstitutions.${institutionIndex}.Course`
                        )}
                        placeholder="Course"
                      />
                    </FormControl>

                    <Stack direction="row" alignItems="center">
                      <FormControl>
                        <Input
                          {...register(
                            `previousInstitutions.${institutionIndex}.duration`
                          )}
                          placeholder="Duration"
                        />
                      </FormControl>
                      <IconButton
                        colorScheme="red"
                        aria-label="Delete Institution"
                        icon={<FiTrash2 />}
                        onClick={() => {
                          removeInstitution(institutionIndex); // Remove the institution at the specified index
                        }}
                      />
                    </Stack>
                    {/* </Stack> */}
                  </SimpleGrid>
                )
              )}

              {/* <SimpleGrid
                                my="2"
                                spacing='10px'
                                columns={{ sm: 2, md: 4 }}
                                sx={{ paddingY: 1 }}
                            >

                                <FormControl>
                                    <CreatableSelect options={[{ label: "ICS", value: "ICS" }, { label: "Vidual", value: "Vidual" }, { label: "ITI", value: "ITI" }]} />
                                </FormControl>

                                <FormControl>
                                    <Input placeholder="Institute Address" />
                                </FormControl>


                                <FormControl>
                                    <Input placeholder="Course" />
                                </FormControl>

                                <Stack direction={"row"}>

                                    <FormControl>
                                        <Input placeholder="Status" />
                                    </FormControl>
                                    <IconButton
                                        colorScheme='red'
                                        icon={<FiTrash2 />}
                                    />

                                </Stack>






                            </SimpleGrid> */}

              {/* Previous Institutes section ends  */}

              <Box
                {...getDocumentRootProps()}
                p={4}
                borderWidth={2}
                borderColor={
                  !isDocumentDragActive ? mainThemeColor : "green"
                }
                borderStyle="dashed"
                borderRadius="md"
                textAlign="center"
                cursor="pointer"
                minH={"200px"}
              >
                <input {...getDocumentInputProps()} />
                {isDocumentDragActive ? (
                  <p>Drop the documents here...</p>
                ) : (
                  <p>
                    Drag and drop documents here, or click to select
                    files
                  </p>
                )}
              </Box>
              <SimpleGrid
                columns={{ sm: 1, md: 4 }}
                style={{ marginTop: 5, marginBottom: "50px" }}
                spacing={2}
              >
                {acceptedFiles.documents.map((file, index) => (
                  <Box
                    key={index}
                    display="flex"
                    flexDirection={"column"}
                    alignItems="flex-end"
                    justifyContent="flex-start"
                    style={{
                      height: "100%",
                      // backgroundColor:"violet"
                    }}
                  >
                    <>
                      {file.type.startsWith("image/") ? (
                        // Display image preview

                        <img
                          src={URL.createObjectURL(file)}
                          alt="Preview"
                          style={{
                            width: "auto",
                            height: "200px",
                            // position :"absolute"
                          }}
                        />
                      ) : file.type === "application/pdf" ? (
                        // Display a placeholder or link for PDF
                        <span as="span">PDF: {file.name}</span>
                      ) : (
                        // Other file types could be handled here
                        <span as="span">{file.name}</span>
                      )}

                      <IconButton
                        position={"absolute"}
                        // top={0}
                        variant="solid"
                        colorScheme="red"
                        icon={<FiTrash2 />}
                        aria-label="Remove document"
                        onClick={() => removeDocument(file.name)}
                      />
                    </>
                  </Box>
                ))}
              </SimpleGrid>
            </form>
          </DrawerBody>

          <DrawerFooter zIndex={-12}>
            <Button
              type="submit"
              colorScheme={mainThemeColor}
              minW={"250px"}
              form="my-form"
            >
              Add Student
            </Button>

            <Button
              type="button"
              colorScheme={mainThemeColor}
              onClick={() => console.log("Console", acceptedFiles)}
            >
              Console
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
