import { useState } from "react";
import { Box, Text } from '@chakra-ui/react';

import Kalend, { CalendarView } from 'kalend' // import component
import 'kalend/dist/styles/index.css'; // import styles


export default function Attendence() {

    // console.log(new Date().toUTCString())

    const events = [
        {
            id: 1,
            startAt: '2023-06-21T15:00:00.000Z',
            endAt:   '2023-06-21T17:00:00.000Z',
            timezoneStartAt: 'Asia/Kolkata', // optional
            summary: 'React calendar component with support for multiple views and events',
            color: 'blue',
            calendarID: 'work',
            isTaskChecked: true

        },
        // {
        //     id: 2,
        //     startAt: '2023-06-22T18:00:00.000Z',
        //     endAt: '2023-06-22T19:00:00.000Z',
        //     timezoneStartAt: 'Asia/Kolkata', // optional
        //     summary: 'React calendar component with support for multiple views and events',
        //     color: 'pink',
        //     calendarID: 'work'
        // },
        {
            id: 3,
            startAt: '2023-06-23T11:00:00.000Z',
            endAt: '2023-06-23T14:00:00.000Z',
            timezoneStartAt: 'Asia/Kolkata', // optional
            summary: 'React calendar component with support for multiple views and events',
            color: 'red',
            calendarID: 'work'
        },
    ]


const onEventClick = (e) => {
    console.log(e)
}

const onNewEventClick = () => { 

}

const onSelectView = () => {

}

const onPageChange = () => {
    console.log("page changed")
}




    return (
        <Box  h="100%" p="2" borderRadius="8" >

            <Text>Attendence</Text>
            <Kalend
                onEventClick={(e)=>onEventClick(e)}
                onNewEventClick={onNewEventClick}
                events={events}
                initialDate={new Date().toISOString()}
                hourHeight={60}
                initialView={CalendarView.WEEK}
                disabledViews={[CalendarView.DAY]}
                onSelectView={onSelectView}
                timezone={"Asia/Kolkata"}
                isNewEventOpen={true}
                showTimeLine={true}
                // isDark={true}
                // selectedView={"week"}    
                onPageChange={onPageChange}
                timeFormat={'12'}
                weekDayStart={'Monday'}
                calendarIDsHidden={['work']}
                language={'en'}
            />

        </Box>
    )
}
