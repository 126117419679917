import React, { useState } from "react";
import {
  Box,
  Flex,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Button,
  SimpleGrid,
  Avatar,
  AvatarGroup,
  useBreakpointValue,
  IconProps,
  Icon,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { AuthContext } from "../utils/contexts/AuthContext";
import { AuthApis, logsApi } from "../utils/Apis/Apis";
import { send_post } from "../utils/hooks/AxiosHooks";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";

const avatars = [
  {
    name: "Ryan Florence",
    url: "https://bit.ly/ryan-florence",
  },
  {
    name: "Segun Adebayo",
    url: "https://bit.ly/sage-adebayo",
  },
  {
    name: "Kent Dodds",
    url: "https://bit.ly/kent-c-dodds",
  },
  {
    name: "Prosper Otemuyiwa",
    url: "https://bit.ly/prosper-baba",
  },
  {
    name: "Christian Nwamba",
    url: "https://bit.ly/code-beast",
  },
];

export default function StudentLogin() {
  var navigate = useNavigate();

  const { AppFunctions, appStates, theme } =
    React.useContext(AuthContext);

  // console.log( "Student Login" , appStates )

  const toast = useToast();

  // console.log(appStates)

  const avatarSize = useBreakpointValue({ base: "md", md: "lg" });
  const flexMinWidth = useBreakpointValue({
    base: "44px",
    md: "60px",
  });
  const flexMinHeight = useBreakpointValue({
    base: "44px",
    md: "60px",
  });

  // const [username, setUsername] = useState("")
  // const [password, setPassword] = useState("")
  const [aadharNo, setAadharNo] = useState("");
  const [mobileNo, setMobileNo] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const checklogin = () => {
    // console.log(appStates.Local_ip_url+AuthApis.StudentLogin);
    // return;

    setIsLoading(true);
    send_post(appStates.Local_ip_url + AuthApis.StudentLogin, {
      aadharNo,
      mobileNo,
    })
      .then((res) => {
        // console.log(res.statuscode())

        setIsLoading(false);
        // console.log(res.data)
        if (res.data.success) {
          // secureLocalStorage.clear();
          localStorage.setItem(
            "userData",
            JSON.stringify(res.data.data)
          );

          secureLocalStorage.setItem("userData", res.data.data);
          AppFunctions.StudentloginSuccess(res.data);

          console.log(appStates);

          navigate("/myPortal");
          // window.location.reload();
        }
      })
      .catch((err) => {
        setIsLoading(false);

        // console.log(JSON.stringify(err.message))

        toast({
          title: "Wrong Credentials Provided",
          description: err.message,
          status: "error",
          duration: 2500,
          isClosable: true,
        });

        send_post(logsApi.senderror, {
          error: err,
        });
      });
  };

  return (
    <Box position={"relative"}>
      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack spacing={{ base: 10, md: 20 }}>
          <Heading
            lineHeight={1.1}
            fontSize={{
              base: "3xl",
              sm: "4xl",
              md: "5xl",
              lg: "6xl",
            }}
          >
            Create Your Own World{" "}
            <Text
              as={"span"}
              bgGradient="linear(to-r, red.400,pink.400)"
              bgClip="text"
            >
              +
            </Text>{" "}
            Full of opportunities
          </Heading>
          <Stack direction={"row"} spacing={4} align={"center"}>
            <AvatarGroup>
              {avatars.map((avatar) => (
                <Avatar
                  key={avatar.name}
                  name={avatar.name}
                  src={avatar.url}
                  size={avatarSize}
                  position={"relative"}
                  zIndex={2}
                  _before={{
                    content: '""',
                    width: "full",
                    height: "full",
                    rounded: "full",
                    transform: "scale(1.125)",
                    bgGradient: "linear(to-bl, red.400,pink.400)",
                    position: "absolute",
                    zIndex: -1,
                    top: 0,
                    left: 0,
                  }}
                />
              ))}
            </AvatarGroup>
            <Text
              fontFamily={"heading"}
              fontSize={{ base: "4xl", md: "6xl" }}
            >
              +
            </Text>
            <Flex
              align={"center"}
              justify={"center"}
              fontFamily={"heading"}
              fontSize={{ base: "sm", md: "lg" }}
              bg={"gray.800"}
              color={"white"}
              rounded={"full"}
              minWidth={flexMinWidth}
              minHeight={flexMinHeight}
              position={"relative"}
              _before={{
                content: '""',
                width: "full",
                height: "full",
                rounded: "full",
                transform: "scale(1.125)",
                bgGradient: "linear(to-bl, orange.400,yellow.400)",
                position: "absolute",
                zIndex: -1,
                top: 0,
                left: 0,
              }}
            >
              YOU
            </Flex>
          </Stack>
        </Stack>
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: "lg" }}
        >
          <Stack spacing={4}>
            <Heading
              color={"gray.800"}
              lineHeight={1.1}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
            >
              Login In to Your World
              <Text
                as={"span"}
                bgGradient="linear(to-r, red.400,pink.400)"
                bgClip="text"
              >
                !
              </Text>
            </Heading>
            <Text
              color={"gray.500"}
              fontSize={{ base: "sm", sm: "md" }}
            >
              Welcome To Etech Computer Academy
            </Text>
            <Text
              color={"gray.500"}
              fontSize={{ base: "sm", sm: "md" }}
            >
              Check Your Class Schedule , New Courses , Syllabus , New
              Courses & Download Your Certificates all in one place !
            </Text>
          </Stack>
          <Box as={"form"} mt={10}>
            <Stack spacing={4}>
              <Input
                onChange={(e) => setMobileNo(e.target.value)}
                placeholder="Mobile No"
                bg={"gray.200"}
                border={0}
                color={"gray.500"}
                _placeholder={{
                  color: "gray.500",
                }}
              />
              <Input
                onChange={(e) => setAadharNo(e.target.value)}
                placeholder="AadharCard No"
                bg={"gray.200"}
                border={0}
                color={"gray.500"}
                _placeholder={{
                  color: "gray.500",
                }}
              />
              {/* <Input
                placeholder="+1 (___) __-___-___"
                bg={'gray.100'}
                border={0}
                color={'gray.500'}
                _placeholder={{
                  color: 'gray.500',
                }}
              /> */}
            </Stack>
            <Button
              onClick={checklogin}
              isLoading={isLoading}
              // spinner={<BeatLoader size={8} color='white' />} in spinner we can pass a custom react element
              loadingText={"checking...."}
              fontFamily={"heading"}
              mt={8}
              w={"full"}
              bgGradient="linear(to-r, red.400,pink.400)"
              color={"white"}
              _hover={{
                bgGradient: "linear(to-r, red.400,pink.400)",
                boxShadow: "xl",
              }}
            >
              Submit
            </Button>
          </Box>
          {/* Placeholder text */}
        </Stack>
      </Container>
      <Blur
        position={"absolute"}
        top={-10}
        left={-10}
        style={{ filter: "blur(70px)" }}
      />
    </Box>
  );
}

export const Blur = (props) => {
  const iconWidth = useBreakpointValue({
    base: "100%",
    md: "40vw",
    lg: "30vw",
  });
  const iconZIndex = useBreakpointValue({ base: -1, md: -1, lg: -1 });
  return (
    <Icon
      width={iconWidth}
      zIndex={iconZIndex}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="71" cy="61" r="111" fill="#F56565" />
      <circle cx="244" cy="106" r="139" fill="#ED64A6" />
      <circle cy="291" r="139" fill="#ED64A6" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
    </Icon>
  );
};
