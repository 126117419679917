export const AuthApis = {
  login: "Auth/login",
  StudentLogin: "Auth/studentlogin",
  verify: "Auth/verifyOtp",
  resendOtp: "Auth/resendotp",
};

export const AdminApis = {
  //Events Api
  getEvents: "Events/getEvents",
  addEvent: "Events/addEvent",
  deleteEvent: "Events/deleteEvent",
  updateEvent: "Events/updateEvent",

  // Institutes Apis
  getInstitutions: "Institutes/getInstitutions",
  addInstitution: "Institutes/addInstitution",
  deleteInstitution: "Institutes/deleteInstitution",
  updateInstitution: "Institutes/updateInstitution",

  // NameTitles Apis
  getNameTitles: "NameTitles/getNameTitles",
  addNameTitle: "NameTitles/addNameTitle",
  deleteNameTitle: "NameTitles/deleteNameTitle",
  updateEventName: "NameTitles/updateEventName",

  // OtherRefrers Apis
  getOtherRefrers: "OtherRefrers/getOtherRefrers",
  addOtherRefrers: "OtherRefrers/addOtherRefrers",
  deleteOtherRefrers: "OtherRefrers/deleteOtherRefrers",
  updateOtherRefrers: "OtherRefrers/updateOtherRefrers",

  // Qualifications Apis
  getQualifications: "Qualifications/getQualifications",
  addQualifications: "Qualifications/addQualifications",
  deleteQualifications: "Qualifications/deleteQualifications",
  updateQualifications: "Qualifications/updateQualifications",

  // Students Apis
  getStudents: "Students/getStudents",
  addStudent: "Students/addStudent",
  addStudentProfileImage: "Students/addStudentProfileImage",
  deleteStudent: "Students/deleteStudent",
  updateStudent: "Students/updateStudent",
  getMyInfo: "Students/myInfo",

  getStudentDetails: "Students/getStudentDetails",
  // getStudentProfile: "getStudentProfile", // parameter is student id

  // AddFee
  getFeesDetails: "StudentFee/getFeesDetails",
  // fetchFeeStudents: "StudentFee/getFeesDetails",
  addStudentFee: "StudentFee/addStudentFee",
  deleteStudentFeeSection: "StudentFee/deleteStudentFeeSection",

  // Leads Apis
  getLeads: "Leads/getLeads",
  addLead: "Leads/addLead",
  deleteLead: "Leads/deleteLead",
  updateLead: "Leads/updateLead",

  // Do Addmission
  getAdmission: "Admission/getAdmission",
  addAdmission: "Admission/addAdmission",
  deleteAdmission: "Admission/deleteAdmission",

  // Followup Api

  getFollowUp: "FollowUp/getFollowUp",
  addFollowUp: "FollowUp/addFollowUp",
  deleteFollowUp: "FollowUp/deleteFollowUp",

  // Courses Apis
  getCourses: "Course/getCourses",
  addCourse: "Course/addCourse",
  deleteCourse: "Course/deleteCourse",
  updateCourse: "Course/updateCourse",

  // Admissions Apis
  getMyAdmissions: "studentPortal/myAdmissions",

  // validate Certificate
  validateCertificate: "certificateValidation/validate",
  validateExtraLayer: "certificateValidation/extra",

  // Batches Api
  getBatches: "Batch/getbatches",
  addBatch: "Batch/addbatch",
  deleteBatch: "Batch/deletebatch",
  updateBatch: "Batch/updatebatch",
};

export const logsApi = {
  senderror: "Logs/PostError",
};

export const coursesApi = {
  getCourses: "Course/getCourses",
  addCourse: "Courses/getCourses",
};

export const UserApis = {};
