import React, { useState } from "react"; // Import useState
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  FormControl,
  FormLabel,
  Input,
  Select,
  useDisclosure,
  NumberInput,
  NumberInputField,
  Image,
  Box,
  Avatar,
  AvatarBadge,
  Flex,
  Center,
} from "@chakra-ui/react";
import { AdminApis, logsApi } from "../../utils/Apis/Apis.js";
import { useToast } from "@chakra-ui/react";
import { send_post } from "../../utils/hooks/AxiosHooks.js";
import { useDropzone } from "react-dropzone";

var mainThemeColor = "purple";

const AddCourse = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { control, handleSubmit, reset, watch } = useForm();
  const toast = useToast();

  // State to store the uploaded image
  const [coverImage, setCoverImage] = useState(null);

  const onSubmit2 = (data) => {
    // Include the cover image data in the form data
    data.coverImage = coverImage;

    send_post(AdminApis.addCourse, JSON.stringify(data))
      .then((res) => {
        const response = res.data;
        console.log(response);
        if (response.data.acknowledged) {
          toast({
            title: response.message,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
        if (res.status === 200) {
          reset();
          onClose();
        }
      })
      .catch((err) => {
        send_post(logsApi.senderror, {
          error: err,
        });
      });
  };

  const onSubmit = (data) => {
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append("image", file);
    formData.append("coursedata", JSON.stringify(data));

    send_post(AdminApis.addCourse, formData)
      .then((res) => {
        const response = res.data;
        console.log(response);

        if (response.data.acknowledged) {
          toast({
            title: response.message,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }

        if (res.status === 200) {
          reset();
          onClose();
        }
      })
      .catch((err) => {
        send_post(logsApi.senderror, {
          error: err,
        });
      });
  };

  const imageUrl = watch("coverImageUrl", "");

  // Function to handle file input change and set the cover image state
  const handleCoverImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setCoverImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      accept: "image/*",
      maxFiles: 1,
    });

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Add Course
      </Button>

      <Drawer isOpen={isOpen} onClose={onClose} size={"xl"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody>
            <form id="course-form" onSubmit={handleSubmit(onSubmit)}>
              <FormControl isRequired>
                <FormLabel>Course Name</FormLabel>
                <Controller
                  name="course"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <Input {...field} />}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Short Name</FormLabel>
                <Controller
                  name="shortName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <Input {...field} />}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Duration</FormLabel>
                <Controller
                  name="duration"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <Input {...field} />}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Teachers</FormLabel>
                <Controller
                  name="teachers"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select {...field} isMulti>
                      <option value="Ajay">Ajay</option>
                      <option value="Diya">Diya</option>
                    </Select>
                  )}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Fee</FormLabel>
                <Controller
                  name="fee"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumberInput>
                      <NumberInputField {...field} />
                    </NumberInput>
                  )}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Offer Fee</FormLabel>
                <Controller
                  name="offerFee"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumberInput>
                      <NumberInputField {...field} />
                    </NumberInput>
                  )}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Cover Image</FormLabel>
                <Box
                  alignItems={"center"}
                  {...getRootProps()}
                  p={4}
                  borderWidth={2}
                  borderColor={
                    !isDragActive ? mainThemeColor : "green"
                  }
                  borderStyle="dashed"
                  // borderRadius={"full"}
                  textAlign="center"
                  cursor="pointer"
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the files here...</p>
                  ) : (
                    <>
                      {acceptedFiles.length > 0 ? (
                        <>
                          <Avatar
                            size="2xl"
                            src={URL.createObjectURL(
                              acceptedFiles[0]
                            )}
                          >
                            {/* <AvatarBadge name="Profile" size="xs" borderColor="papayawhip" bg="green" boxSize="1em" /> */}
                          </Avatar>
                        </>
                      ) : (
                        <Avatar size={"2xl"}>
                          {/* <AvatarBadge name="Profile" size="xs" borderColor="papayawhip" bg="tomato" boxSize="1em" /> */}
                        </Avatar>
                      )}
                    </>
                  )}
                </Box>
              </FormControl>
            </form>
          </DrawerBody>
          <DrawerFooter>
            <Button
              type="submit"
              colorScheme="blue"
              form="course-form"
            >
              Add Course
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default AddCourse;
