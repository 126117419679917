import { Box, Table, Tr, Td, Button, Flex, Grid, GridItem, useToast, HStack, Heading, Image, Input, SimpleGrid, TableContainer, VStack, useColorModeValue, TableCaption, InputGroup, InputLeftElement, InputRightElement, InputRightAddon } from '@chakra-ui/react'
import React, { useState, useEffect, useRef } from 'react'
import { Form } from 'react-hook-form'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react'
import { send_get, send_post } from '../utils/hooks/AxiosHooks'
import { AdminApis, logsApi } from '../utils/Apis/Apis'
import { useNavigate, useParams } from 'react-router-dom'
import Header from '../Mainsite/Parts/Header'
import Footer from '../Mainsite/Parts/Footer'
import { LockIcon, PhoneIcon } from '@chakra-ui/icons'
import { BiLock, BiUserCircle } from 'react-icons/bi'
import { IoIdCard } from 'react-icons/io5'
import overlayImage from "../Assets/Images/verified.png"
import moment from 'moment'

export default function VerifyCertificate() {

  const toast = useToast()


  const { certificateno } = useParams();
  // console.log("VerifyCertificate",certificateno)


  const [certificateNo, setCertificateNo] = useState(certificateno == undefined ? "" : certificateno);
  const [aadharNo, setAadharNo] = useState("")

  const [registeredMobileNo, setRegisteredMobileNo] = useState("")
  const [certificateData, setCertificateData] = useState(undefined)
  const [securityLayer, setSecurityLayer] = useState("basic")

  const [validation, setvalidation] = useState("loading")

  const navigate = useNavigate();

  const validateCertificate = () => {

    if (certificateNo != undefined || certificateNo != "") {

      send_post(AdminApis.validateCertificate, {
        certificateNo
      }).then((res) => {
        var response = res.data;
        if (response.success == false) {
          setvalidation("fake")
        } else {
          setvalidation("true")

          setCertificateData(response.data)
          // console.log(response.data[0] )

        }
      }).catch((err) => {
        send_post(logsApi.senderror, {
          error: err
        })
      })
    } else {
      console.log("No certificate number provided")
    }
  }


  const validateCertificate2 = (certificateNo2) => {

    if (certificateNo2 != undefined || certificateNo2 != "") {

      send_post(AdminApis.validateCertificate, {
        certificateNo: certificateNo2
      }).then((res) => {
        var response = res.data;
        if (response.success == false) {
          setvalidation("fake")
        } else {
          setvalidation("true")

          setCertificateData(response.data)
          // console.log(response.data[0] )

        }
      }).catch((err) => {
        send_post(logsApi.senderror, {
          error: err
        })
      })
    } else {
      console.log("No certificate number provided")
    }
  }



  useEffect(() => {
    validateCertificate()
  }, [])

  var headingBg = useColorModeValue("gray.500", "white");
  var headingColor = useColorModeValue("white", "gray.900");
  var Inputborder = useColorModeValue("gray.900 dashed", "white");

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);

  const [focusedInput, setFocusedInput] = useState(input1Ref);

  const [inputFirst, setInputFirst] = useState("")
  const [inputSecond, setInputSecond] = useState("")
  const [inputThird, setInputThird] = useState("")

  useEffect(() => {
    const handleKeyDown = (event) => {
      // console.log("pressed " + event.key)
      // console.log("focused",focusedInput)

      if (event.key === 'Backspace' && focusedInput.current != null &&  focusedInput.current.value.length === 0) {
        if (focusedInput === input3Ref) {
          setFocusedInput(input2Ref);
          input2Ref.current.focus();
        } else if (focusedInput === input2Ref) {
          setFocusedInput(input1Ref);
          input1Ref.current.focus();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    // console.log("hello")
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [focusedInput]);


  const verifyCertificateCode = () => {
    var certificateCode = `${inputFirst}-${inputSecond}-${inputThird}`;
    if (inputFirst == "" || inputSecond == "" || inputThird == "" || inputThird.length > 4) {
      toast({
        title: 'Provided Code Not Matching  Requirments',
        description: "Error",
        status: 'error',
        duration: 2500,
        isClosable: true,
      })
    } else {
      navigate(`${inputFirst}-${inputSecond}-${inputThird}`)
      setCertificateNo(certificateCode)
      validateCertificate2(certificateCode);
    }
  }


  const verifyExtraLayer = () => {
    setSecurityLayer("basic")
    // console.log(aadharNo)
    send_post(AdminApis.validateExtraLayer, {
      certificateNo, aadharNo
    }).then((res) => {
      var response = res.data
      // console.log(response.data[0])
      if (response.success == false) {
        toast({
          title: 'Aadhar Card no and Certificate Not Matching',
          description: "Error",
          status: 'error',
          duration: 2500,
          isClosable: true,
        })
      } else {
        if (response.data[0].studentData.length > 0) {
          setCertificateData(response.data)
          setSecurityLayer("Extra")
        } else {
          toast({
            title: 'Aadhar Card no and Certificate Not Matching',
            description: "Error",
            status: 'error',
            duration: 2500,
            isClosable: true,
          })
        }
      }
    }).catch((err) => {
      send_post(logsApi.senderror, {
        error: err
      })
    })

  }


  return (


    <>

      <Box bg={useColorModeValue('white', 'grey.900')} px={4}>


        <Header />
        <hr />
        <Box py="4" my="4" >
          {/* {certificateNo}
        <Heading>{validation}</Heading> */}

          {
            certificateNo == undefined || certificateNo == "" ?
              <Flex flexDirection={"column"} minH={"50vh"} alignItems={"center"} justifyContent={"center"} >
                <Heading>Enter Certificate code</Heading>
                {/* <FormControl> */}
                <HStack my="5">



                  <Input
                    ref={input1Ref}
                    htmlSize={4}
                    width="auto"
                    borderColor={Inputborder}
                    onChange={(e) => {
                      setInputFirst(e.target.value.toUpperCase())
                      if (e.target.value.length === 3) {
                        input2Ref.current.focus();
                        setFocusedInput(input2Ref)
                      }
                    }}
                  />




                  <Heading>-</Heading>
                  <Input
                    ref={input2Ref}
                    htmlSize={4}
                    width="auto"
                    borderColor={Inputborder}
                    onChange={(e) => {
                      setInputSecond(e.target.value.toUpperCase())
                      if (e.target.value.length === 3) {
                        input3Ref.current.focus();
                        setFocusedInput(input3Ref)

                      }
                    }}
                  />
                  {/* <Input htmlSize={4} width='auto' borderColor={Inputborder} />  */}
                  <Heading>-</Heading>
                  {/* <Input htmlSize={4} width='auto'borderColor={Inputborder} /> */}
                  <Input
                    ref={input3Ref}
                    htmlSize={4}
                    width="auto"
                    borderColor={Inputborder}
                    onChange={(e) => {
                      setInputThird(e.target.value.toUpperCase())
                      if (e.target.value.length > 4) {
                        // setFocusedInput(null);
                      }
                    }}
                  />
                </HStack>
                <Button onClick={verifyCertificateCode} colorScheme="green" maxW={"25vw"} w="100%">Verify</Button>
                {/* </FormControl> */}


              </Flex>


              :

              validation == "loading" || validation == "true" ?
                <Grid spacing='10px' templateColumns={{ sm: 'repeat(16, 1fr)', md: 'repeat(16, 1fr)' }} minH={"55vh"} h="100%" >

                  <GridItem display={"flex"}  justifyContent={"center"} colSpan={{ md: 4, base: 16 }} style={{ overflow: "scroll" }} p={"4"} >

                    <Box borderRadius={"md"} overflow={"hidden"} textAlign={"center"} boxSize="fit-content" >
                      {/* <Image src={stuImage} maxH={"310"} alt='Dan Abramov' /> */}
                      <Image src={securityLayer == "Extra" ? certificateData && certificateData[0].studentData[0].student_Image : certificateData && certificateData[0].studentData[0].blurImage} maxH={"310"}
                        alt={certificateData && certificateData[0].studentData[0].student_name} />

                      <Image
                        src={overlayImage}
                        maxH={"100"}
                        alt='Overlay Image'
                        position="absolute"
                        top={330}
                        left={100}
                        zIndex={1}
                      />
                      <Heading size="md" p="3" bg={headingBg} color={headingColor} >{certificateNo}</Heading>
                    </Box>




                  </GridItem>

                  <GridItem colSpan={{ md: 12, base: 16 }} style={{ overflow: "scroll" }} p={"4"} >

                    <TableContainer>
                      <Table>
                        <Tr>
                          <Td>Name</Td>
                          <Td>{certificateData && certificateData[0].studentData[0].student_name}</Td>
                        </Tr>

                        {
                          certificateData && certificateData[0].studentData[0].guardians.map((gaudian, index) => {
                            return (

                              <Tr key={index}>
                                <Td>{`${gaudian.relationship} Name`}</Td>
                                <Td>{`${gaudian.name}`}</Td>
                              </Tr>
                            )
                          })
                        }

                        {
                          securityLayer == "Extra" ?
<>

<Tr>
                              <Td>Gender</Td>
                              <Td>{certificateData && certificateData[0].studentData[0].gender}</Td>
                            </Tr>

                            <Tr>
                              <Td>Address</Td>
                              <Td>{certificateData && certificateData[0].studentData[0].student_address}</Td>
                            </Tr>

                            <Tr>
                              <Td>Date Of Birth</Td>
                              <Td>{certificateData && certificateData[0].studentData[0].student_dob}</Td>
                            </Tr>


                            <Tr>
                              <Td>Course </Td>
                              <Td>{ certificateData &&  certificateData[0].courseData[0].Course }</Td>
                            </Tr>

                            <Tr>
                              <Td>Course Duration</Td>
                              <Td>{ certificateData &&  certificateData[0].admissionData[0].course_duration }</Td>
                            </Tr>

                            <Tr>
                              <Td>Admission Date</Td>
                              <Td>{ moment(certificateData &&  certificateData[0].admissionData[0].admission_date).format("DD/MM/YYYY") }</Td>
                            </Tr>

                            <Tr>
                              <Td>Email Id</Td>
                              <Td>{certificateData && certificateData[0].studentData[0].student_emailId}</Td>
                            </Tr>
</>
                        
                        :
                        <Tr>
                          <Td colSpan={2}  textAlign={"center"} alignItems={"center"} justifyContent={"center"} align="center">
                            
                            
                             {/* <Button colorScheme='blue' leftIcon={<BiLock />} > Unlock to see More</Button> */}
                             
                              </Td>
                        </Tr>
                        }


                        {/* <TableCaption textAlign={"left"} alignItems={"flex-start"}>

                        </TableCaption> */}
                      </Table>
                    </TableContainer>

                    <Heading my="5" size="sm"   >{`Certificate Valid with grade ${certificateData ? certificateData[0].Grade : ""}`}</Heading>

                    <FormControl>
                      <InputGroup mt="3" maxW={"350px"} >
                        <InputLeftElement pointerEvents='none'>
                          <IoIdCard color='gray.300' />
                        </InputLeftElement>

                        <Input value={aadharNo}  onChange={(e) => setAadharNo(e.target.value)} placeholder='Enter Aadhar No' colorScheme='teal' />
                        <InputRightAddon p={0} bg="teal" children={
                          <Button onClick={verifyExtraLayer} leftIcon={<BiLock />} borderLeftRadius={0} m="0" colorScheme='blue' >Unlock</Button>
                          // "Unlock"
                        } />
                      </InputGroup>
                      <FormHelperText>Enter Aadhar Card No To View Complete Details</FormHelperText>
                    </FormControl>


                  </GridItem>

                </Grid>
                :
                <>
                  <Flex direction={"column"} justifyContent={"center"} alignItems={"center"}>

                    <Image src={require("../Assets/Images/fake.png")} maxH={"300"} />
                    <Heading>Certificate Not Valid</Heading>
                  </Flex>
                </>
          }
        </Box>
        <hr />
        <Footer />
      </Box>

    </>

  )
}
