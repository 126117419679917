import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../utils/contexts/AuthContext";
import { AdminApis, coursesApi } from "../utils/Apis/Apis";
import TableTemplate from "./parts/TableTemplate";
import AddCourse from "./Courses/AddCourse";
import { useForm } from "react-hook-form";
import {
  Checkbox,
  Box,
  SimpleGrid,
  HStack,
  VStack,
  Button,
  Stack,
  Image,
  Badge,
  Divider,
  IconButton,
  Text,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Select,
  Grid,
  GridItem,
  Avatar,
  Wrap,
  WrapItem,
  useToast,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Textarea,
  Center,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { FiEdit, FiMessageCircle, FiUser } from "react-icons/fi";
import { Card, CardBody, CardFooter } from "@chakra-ui/react";
import { send_delete, send_get } from "../utils/hooks/AxiosHooks";
// import { coursesColumns } from "../utils/Extras/Columns";

const CoursesList = () => {
  const { AppFunctions, appStates } = useContext(AuthContext);
  const [selectedPic, setSelectedPic] = useState(null);
  const [myBatch, setMyBatch] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [size, setSize] = React.useState("");
  const toast = useToast();

  // useform
  const { register, handleSubmit, reset } = useForm();

  const mysubmit = (data) => {
    console.log(data);
  };

  useEffect(() => {
    Batches();
    if (!appStates.CoursesList || appStates.CoursesList.length <= 0) {
      AppFunctions.getData({
        listType: "CoursesList",
        api: coursesApi.getCourses,
      });
    }
  }, []);

  const Batches = async () => {
    try {
      const res = await send_get(`${AdminApis.getBatches}`);
      console.log(res.data);
      setMyBatch(res);
    } catch (err) {
      console.log(err, "something went wrong");
    }
  };

  const handleCourseDelete = async (id) => {
    try {
      await send_delete(`${AdminApis.deleteCourse}/${id}`);
      AppFunctions.getData({
        listType: "CoursesList",
        api: coursesApi.getCourses,
      });
      toast({
        title: "Course deleted.",
        description: "The course has been successfully deleted.",
        status: "success",
        duration: 1500,
        isClosable: true,
      });
    } catch (err) {
      console.log("Something went wrong", err);
      toast({
        title: "Error deleting course.",
        description: "There was an error deleting the course.",
        status: "error",
        duration: 1500,
        isClosable: true,
      });
    }
  };

  const handleClick = (newSize) => {
    setSize(newSize);
    onOpen();
  };

  const handlePicChange = (event) => {
    const file = event.target.files[0];
    setSelectedPic(URL.createObjectURL(file));
  };

  const handleAvatarClick = () => {
    document.getElementById("fileInput").click();
  };

  return (
    <>
      <AddCourse />

      <SimpleGrid mt={4} columns={{ sm: 1, md: 4 }} spacing={4}>
        {appStates.CoursesList.map((course, index) => (
          <Card maxW="sm" m="0" p="0" key={index}>
            <CardBody p="0">
              <Image
                src={course.CoverImageUrl}
                alt={course.course}
                borderRadius="lg"
                borderBottomLeftRadius={0}
                borderBottomRightRadius={0}
              />
              <HStack m="2">
                <VStack
                  w="100%"
                  justifyContent={"flex-start"}
                  alignItems={"flex-start"}
                  mt={2}
                >
                  <Badge
                    colorScheme="red"
                    textDecoration={"line-through"}
                  >
                    Fee : {course.fee}
                  </Badge>
                  <Badge colorScheme="green">
                    Offer : {course.offer_fee}
                  </Badge>
                </VStack>

                <HStack
                  w="100%"
                  justifyContent={"flex-end"}
                  alignItems={"flex-end"}
                >
                  <IconButton
                    colorScheme="purple"
                    icon={<FiEdit color="#fff" />}
                    key={size}
                    onClick={() => handleClick("md")}
                  />
                  <Button
                    style={{ background: "red", color: "white" }}
                    onClick={() => handleCourseDelete(course._id)}
                  >
                    <DeleteIcon />
                  </Button>
                </HStack>
              </HStack>
            </CardBody>
            <Divider />
            <CardFooter>
              <Stack
                direction="column"
                w="100%"
                spacing={2}
                align="center"
              >
                <Text fontSize={"sm"}>{course.Course}</Text>
                <Button
                  variant={"solid"}
                  colorScheme="teal"
                  w="100%"
                  size={"sm"}
                  rightIcon={<FiMessageCircle />}
                >
                  Set Campaign
                </Button>
                <Button
                  colorScheme="green"
                  size={"sm"}
                  w="100%"
                  rightIcon={<FiUser />}
                >
                  Enroll Student
                </Button>
              </Stack>
            </CardFooter>
          </Card>
        ))}
      </SimpleGrid>

      <Drawer onClose={onClose} isOpen={isOpen} size={size}>
        <DrawerOverlay />
        <DrawerContent padding={"20px"}>
          <DrawerCloseButton />

          <DrawerBody
            css={{ "&::-webkit-scrollbar": { display: "none" } }}
          >
            <form onSubmit={handleSubmit(mysubmit)}>
              <FormControl>
                <Grid
                  templateColumns="repeat(1, 1fr)"
                  gap={4}
                  paddingTop={"20px"}
                >
                  <GridItem
                    width={"100%"}
                    display={"grid"}
                    gap={"10px"}
                  >
                    <FormLabel>Start Time</FormLabel>
                    <Input
                      {...register("Start_Time", {
                        required: true,
                      })}
                      type="time"
                      variant="outline"
                      placeholder="Start_Time"
                    />
                  </GridItem>
                  <GridItem
                    width={"100%"}
                    display={"grid"}
                    gap={"10px"}
                  >
                    <FormLabel>End Time</FormLabel>
                    <Input
                      {...register("End_Time", { required: true })}
                      type="time"
                      variant="outline"
                      placeholder="End_Time"
                    />
                  </GridItem>
                  <GridItem
                    width={"100%"}
                    display={"grid"}
                    gap={"10px"}
                  >
                    <Input
                      {...register("Batch_No", { required: true })}
                      variant="outline"
                      placeholder="Batch_No"
                    />
                  </GridItem>

                  {/* <GridItem
                      mt={8}
                      width={"100%"}
                      display={"grid"}
                      justifyContent={"center"}
                      gap={"10px"}
                      border={"2px dashed black"}
                      padding={"30px 0px"}
                    >
                      <Wrap>
                        <WrapItem>
                          <input
                            id="fileInput"
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handlePicChange}
                          />
                          {selectedPic ? (
                            <img
                              size="2xl"
                              name="Dan Abrahmov"
                              src={selectedPic}
                              alt="Selected Profile Pic"
                              onClick={handleAvatarClick}
                              cursor="pointer"
                            />
                          ) : (
                            <Avatar
                              size="2xl"
                              name="Dan Abrahmov"
                              src="https://bit.ly/dan-abramov"
                              alt="Default Avatar"
                              onClick={handleAvatarClick}
                              cursor="pointer"
                            />
                          )}
                        </WrapItem>
                      </Wrap>
                    </GridItem> */}
                </Grid>
                <Box>
                  <Box mt={6} display={"grid"} gap={"10px"}>
                    <Checkbox
                      size="md"
                      colorScheme="blue"
                      defaultChecked
                    >
                      I agree to terms and conditions
                    </Checkbox>
                    <Button
                      type="submit"
                      colorScheme="blue"
                      onClick={() =>
                        toast({
                          title: "Saved Data",
                          status: "success",
                          duration: 1500,
                          isClosable: true,
                        })
                      }
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </FormControl>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default CoursesList;
