import {
  SimpleGrid,
  Box,
  Avatar,
  HStack,
  Heading,
  VStack,
  Text,
  Button,
  Stack,
  Image,
  Badge,
  Divider,
  ButtonGroup,
  IconButton,
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";

import {
  FiHeart,
  FiLogOut,
  FiMail,
  FiPhone,
  FiSend,
  FiShare,
  FiShare2,
  FiShoppingCart,
  FiThumbsUp,
} from "react-icons/fi";
import { BiArrowBack, BiRupee, BiUserPin } from "react-icons/bi";
import { TbCertificate } from "react-icons/tb";
import { AiOutlineUserAdd } from "react-icons/ai";
import { IoBookOutline } from "react-icons/io5";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "@chakra-ui/react";
import bgCover from "../Assets/Images/backgrounds/bg2-cover-large.jpeg";
import { Grid, GridItem } from "@chakra-ui/react";
import {
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import AllCourses from "./StudentSections/AllCourses";
import StudentInfo from "./StudentSections/StudentInfo";
import Attendence from "./StudentSections/Attendence";
import "react-calendar/dist/Calendar.css";
import { send_get, send_post } from "../utils/hooks/AxiosHooks";
import { AdminApis, logsApi } from "../utils/Apis/Apis";
import Admission from "./StudentSections/Admission";
import Notifications from "./StudentSections/Notifications";
import Certificates from "./StudentSections/Certificates";
import { useDisclosure } from "@chakra-ui/react";

export default function StudentPortal() {
  const [mainpageheight, setMainpageheight] = useState(0);
  const [topnavheight, setTopnavheight] = useState(0);
  const [floatingcardheight, setFloatingcardheight] = useState(0);
  const [floatingbuttonheight, setFloatingbuttonheight] = useState(0);

  const maindiv = useRef(null);
  const topnav = useRef(null);
  const floatingcard = useRef(null);
  const floatingbutton = useRef(null);

  const [studentInfo, setStudentInfo] = React.useState(null);
  const [admissionsList, setAdmissionsList] = React.useState([]);

  const getMyInfo = () => {
    send_get(AdminApis.getMyInfo)
      .then((res) => {
        var response = res.data;
        setStudentInfo(response.data);
      })
      .catch((err) => {
        send_post(logsApi.senderror, {
          error: err,
        });
      });
  };

  const getMyAdmissions = () => {
    send_get(AdminApis.getMyAdmissions)
      .then((res) => {
        var response = res.data;
        setAdmissionsList(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        send_post(logsApi.senderror, {
          error: err,
        });
      });
  };

  useEffect(() => {
    getMyInfo();
    getMyAdmissions();
  }, []);

  useEffect(() => {
    // setHeight(ref.current.clientHeight)
    // setMheight(maindiv.current.clientHeight)

    setMainpageheight(maindiv.current.clientHeight);
    setTopnavheight(topnav.current.clientHeight);
    setFloatingcardheight(floatingcard.current.clientHeight);
    setFloatingbuttonheight(floatingbutton.current.clientHeight);
  });

  const [selectedMenuItem, setSelectedMenuItem] =
    useState("Student Info");

  const handleMenuSelection = (item) => {
    console.log("handleMenuSelection", item);
    setSelectedMenuItem(item);
  };

  var sideMenu = [
    {
      name: "All Courses",
      icon: IoBookOutline,
    },
    {
      name: "Student Info",
      icon: BiUserPin,
    },
    {
      name: "Admissions",
      icon: AiOutlineUserAdd,
    },
    {
      name: "Attendence",
      icon: BiUserPin,
    },
    // {
    //   name: "Fee",
    //   icon: BiRupee
    // },
    // {
    //   name: "Certificates",
    //   icon: TbCertificate
    // },
    {
      name: "Notifications",
      // icon: IoNotificationsCircleOutline,
    },
  ];

  // const {}

  return (
    // main div

    <Box ref={maindiv} minH={"100vh"} maxH={"100vh"}>
      {/* top nav bar  */}
      <SimpleGrid
        ref={topnav}
        bg="purple.200"
        bgImage={bgCover}
        style={{ backgroundSize: "cover" }}
        columns={{ base: 1, md: 2 }}
        padding={5}
      >
        <Box style={{ position: "sticky", top: 0 }} bg={"#fffff06"}>
          <HStack spacing={5}>
            {/* <Avatar src={"https://images.unsplash.com/photo-1438761681033-6461ffad8d80"} size="xl" border={"2px solid"} />  */}
            <Avatar
              src={studentInfo ? `${studentInfo.student_Image}` : ""}
              size="xl"
              border={"2px solid"}
            />
            <VStack
              justifyContent={"center"}
              alignItems={"flex-start"}
              h="100%"
            >
              <Heading
                bg={"#000000ad"}
                p="2"
                borderRadius={5}
                size={{ base: "xs", sm: "md" }}
                color={"#fff"}
              >
                {studentInfo ? studentInfo.student_address : ""}
              </Heading>
              <Text
                bg={"#000000ad"}
                p="2"
                borderRadius={5}
                fontSize={{ base: "xs", sm: "sm" }}
                color={"#fff"}
              >
                {studentInfo ? studentInfo.student_name : ""}
                <Text>
                  {studentInfo ? studentInfo.mobile_no : ""}
                </Text>
              </Text>
            </VStack>
          </HStack>
        </Box>

        <HStack justifyContent={"flex-end"} alignItems={"flex-end"}>
          <Button
            mt="2"
            bg="#fff"
            rightIcon={<FiSend />}
            variant={"solid"}
          >
            Edit Profile
          </Button>
        </HStack>
      </SimpleGrid>
      {/* top nav bar ends  */}

      {/* content grid  */}

      <Grid
        bg="gray.200"
        style={{
          height: `calc(${mainpageheight}px - ${topnavheight}px)`,
        }}
        templateColumns={{
          sm: "repeat(16, 1fr)",
          base: "repeat(16, 1fr)",
        }}
        overflow={"scroll"}
      >
        <GridItem
          colSpan={{ sm: 3, base: 16 }}
          style={{ overflow: "scroll" }}
          display={{ base: "none", sm: "block" }}
          p={"4"}
          //  bg="#dcdad3"
        >
          <VStack
            bg={"#fff"}
            borderRadius={8}
            h={`calc(${mainpageheight}px - ${topnavheight}px - ${floatingcardheight}px - ${floatingbuttonheight}px - 60px )`}
          >
            <List w="100%">
              <ListItem>
                <Text
                  color={"green.600"}
                  textAlign={"center"}
                  fontWeight={"bold"}
                >
                  Class Timings
                </Text>
              </ListItem>
            </List>

            <List w={"100%"} px="3" overflow={"scroll"}>
              {/* {
                [1, 2, 3, 4, 5].map((index) => {
                  // console.log(index)
                  return (



                    <HStack shadow={"md"} p={2} bg={index % 2 == 0 ? "red.400" : "purple.400"} borderRadius={8} my="2" key={index} >
                      <VStack w="100%" >
                        <Text fontWeight={"bold"} fontSize={"small"} color={"#fff"} >Spoken English</Text>
                        <Text color={"#fff"} fontSize={"xx-small"}><Badge colorScheme="purple" >Mon</Badge> - <Badge colorScheme="pink" >Fri</Badge> </Text>
                      </VStack>
                      <VStack w="100%">
                        <Badge colorScheme='green' >9:00 Am </Badge>
                        <Badge colorScheme='red' >10:00 Am </Badge>
                      </VStack>
                    </HStack>

                  )
                })
              } */}

              {admissionsList && admissionsList.length > 0
                ? admissionsList.map((admission, index) => {
                    var courseData = admission.courseData[0];
                    var batchData = admission.batchData[0];
                    // console.log(index)
                    return (
                      <HStack
                        shadow={"md"}
                        p={2}
                        bg={index % 2 == 0 ? "red.400" : "purple.400"}
                        borderRadius={8}
                        my="2"
                        key={index}
                      >
                        <VStack w="100%">
                          <Text
                            fontWeight={"bold"}
                            fontSize={"small"}
                            color={"#fff"}
                          >
                            {courseData.ShortName}
                          </Text>
                          <Text color={"#fff"} fontSize={"xx-small"}>
                            <Badge colorScheme="purple">Mon</Badge> -
                            <Badge colorScheme="pink">Fri</Badge>
                          </Text>
                        </VStack>
                        {/* <Divider orientation='vertical' /> */}
                        <VStack w="100%">
                          <Badge colorScheme="green">
                            {batchData.startingTime}
                          </Badge>
                          <Badge colorScheme="red">
                            {batchData.EndTime}
                          </Badge>
                        </VStack>
                      </HStack>
                    );
                  })
                : ""}
            </List>
          </VStack>

          <List
            spacing={3}
            ref={floatingcard}
            bg={"#ffff"}
            borderRadius={8}
            mt="3"
            style={{ position: "sticky", bottom: 50 }}
          >
            {sideMenu.map((item) => {
              return (
                <ListItem
                  p="1"
                  bg={
                    selectedMenuItem == item.name
                      ? "purple.500"
                      : null
                  }
                  color={
                    selectedMenuItem == item.name ? "#fff" : null
                  }
                  className="clickable"
                  onClick={() => handleMenuSelection(item.name)}
                >
                  <ListIcon
                    as={item.icon}
                    color={
                      selectedMenuItem == item.name
                        ? "#fff"
                        : "purple"
                    }
                  />
                  {item.name}
                </ListItem>
              );
            })}
          </List>

          <Button
            mt="3"
            ref={floatingbutton}
            colorScheme="green"
            width={"100%"}
            leftIcon={<FiLogOut />}
            style={{ position: "sticky", bottom: 5 }}
          >
            Go Back
          </Button>
        </GridItem>

        <GridItem
          colSpan={{ sm: 13, base: 16 }}
          p={"4"}
          overflow={"scroll"}
        >
          <Box
            bg={"#fff"}
            p="3"
            px="5"
            h="100%"
            borderRadius={8}
            overflow={"scroll"}
          >
            {selectedMenuItem == "All Courses" ? (
              <AllCourses />
            ) : selectedMenuItem == "Student Info" ? (
              <StudentInfo studentInfo={studentInfo} />
            ) : selectedMenuItem == "Admissions" ? (
              <Admission admissionsList={admissionsList} />
            ) : selectedMenuItem == "Notifications" ? (
              <Notifications admissionsList={admissionsList} />
            ) : selectedMenuItem == "Certificates" ? (
              <Certificates admissionsList={admissionsList} />
            ) : selectedMenuItem == "Attendence" ? (
              <Attendence />
            ) : null}
          </Box>
        </GridItem>
      </Grid>

      {/* content grid end */}

      {/* main div end */}
    </Box>
  );
}
