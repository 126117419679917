import React, { useEffect } from "react";
import AddStudent from "./StudentsList/AddStudent.js";
import { AuthContext } from "../utils/contexts/AuthContext";
import { AdminApis } from "../utils/Apis/Apis";
import TableTemplate from "./parts/TableTemplate";
import { studentColumns } from "../utils/Extras/Columns";

const StudentList = () => {
  const { AppFunctions, appStates } = React.useContext(AuthContext);

  useEffect(() => {
    if (
      appStates.StudentsList &&
      appStates.StudentsList.length <= 0
    ) {
      AppFunctions.getData({
        listType: "StudentsList",
        api: AdminApis.getStudents,
      });
    }
  }, []);

  return (
    <div>
      <TableTemplate
        data={appStates.StudentsList}
        columns={studentColumns}
        Add_data={AddStudent}
      />
    </div>
  );
};

export default StudentList;
