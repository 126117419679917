import React, { useState } from "react";
import { Stack, Flex, Input, Box, Text } from "@chakra-ui/react";
import { DeleteIcon, AddIcon } from "@chakra-ui/icons";
import { Select } from "@chakra-ui/react";

const DynamicInputFields = () => {
  const [inputFields, setInputFields] = useState([
    { id: 1, value: "" },
  ]);

  const handleAddField = () => {
    const newInputFields = [
      ...inputFields,
      { id: inputFields.length + 1, value: "" },
    ];
    setInputFields(newInputFields);
  };

  const handleRemoveField = (id) => {
    const updatedInputFields = inputFields.filter(
      (field) => field.id !== id
    );
    setInputFields(updatedInputFields);
  };

  return (
    <Stack spacing={4}>
      <Box
        style={{ display: "flex", alignItems: "center", gap: "10px" }}
      >
        <Text
          mt={2}
          style={{ color: "#8a148a" }}
          fontWeight={600}
          fontSize="21px"
        >
          Gaurdians
        </Text>
        <AddIcon
          mt={2}
          style={{
            border: "1px solid lightgray",
            padding: "7px",
            fontSize: "25px",
            borderRadius: "10px",
            background: "#805ad5",
            color: "white",
          }}
          onClick={handleAddField}
        />
      </Box>
      <Box style={{ display: "grid", gap: "20px" }}>
        {inputFields.map((field) => (
          <Flex gap={3} key={field.id}>
            <Select
              name="Parent"
              // value={formData.Parent}
              // onChange={handleInputData}
              placeholder="Select"
            >
              <option value="Mother">Mother</option>
              <option value="Father">Father</option>
              <option value="Sister">Sister</option>
            </Select>

            <Input
              name="GaurdianName"
              // value={formData.GaurdianName}
              // onChange={handleInputData}
              placeholder="Guardian Name"
            />
            <Input
              name="GaurdianOccupation"
              // value={formData.GaurdianOccupation}
              // onChange={handleInputData}
              placeholder="Occupation"
            />
            <Input
              name="GaurdianNumber"
              // value={formData.GaurdianNumber}
              // onChange={handleInputData}
              placeholder="Mobile Number"
            />
            <DeleteIcon
              style={{
                border: "1px solid lightgray",
                width: "50px",
                height: "40px",
                padding: "10px",
                marginRight: "20px",
                borderRadius: "10px",
                background: "#e53e3e",
                color: "white",
              }}
              onClick={() => handleRemoveField(field.id)}
            />
          </Flex>
        ))}
      </Box>
    </Stack>
  );
};

export default DynamicInputFields;
