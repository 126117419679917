import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Tooltip } from "@chakra-ui/react";
import { send_get, send_post } from "../../utils/hooks/AxiosHooks";
import { AdminApis, logsApi } from "../../utils/Apis/Apis";
import { AuthContext } from "../../utils/contexts/AuthContext";

export default function StudentInfo({ studentInfo }) {
  const { AppFunctions, appStates, theme } =
    React.useContext(AuthContext);

  return (
    <div>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Td fontWeight={"bold"} colSpan={2}>
                Student Info
              </Td>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Student Name</Td>
              <Td>{studentInfo ? studentInfo.student_name : ""}</Td>
            </Tr>

            <Tr>
              <Td>Mobile No.</Td>
              <Td>{studentInfo ? studentInfo.mobile_no : ""}</Td>
            </Tr>

            <Tr>
              <Td>Student Address</Td>
              <Td>
                {studentInfo ? studentInfo.student_address : ""}
              </Td>
            </Tr>

            <Tr>
              <Td>Aadhar Card no</Td>
              <Td>{studentInfo ? studentInfo.aadhar_card_no : ""}</Td>
            </Tr>

            <Tr>
              <Td>Email Id</Td>
              <Td>
                {studentInfo ? studentInfo.student_emailId : ""}
              </Td>
            </Tr>

            <Tr>
              <Td>Date Of Birth</Td>
              <Td>{studentInfo ? studentInfo.student_dob : ""}</Td>
            </Tr>

            {studentInfo && studentInfo.qualifications.length > 0 ? (
              <>
                <Tr>
                  <Td fontWeight={"bold"} colSpan={2}>
                    Qualifications
                  </Td>
                </Tr>
                {studentInfo &&
                  studentInfo.qualifications.map(
                    (qualification, index) => {
                      return (
                        <Tr key={index}>
                          {/* <Td>Qualifications</Td> */}
                          <Td colSpan={2}>{qualification}</Td>
                        </Tr>
                      );
                    }
                  )}
              </>
            ) : (
              ""
            )}

            {studentInfo && studentInfo.guardians.length > 0 ? (
              <>
                <Tr>
                  <Td fontWeight={"bold"} colSpan={2}>
                    Gaurdians
                  </Td>
                </Tr>

                {studentInfo &&
                  studentInfo.guardians.map((guardian, index) => {
                    return (
                      <Tr key={index}>
                        <Td>{guardian.relationship} Name</Td>
                        <Td>{guardian.name}</Td>
                      </Tr>
                    );
                  })}
              </>
            ) : (
              ""
            )}

            {studentInfo &&
            studentInfo.previousInstitutions.length > 0 ? (
              <>
                <Tr>
                  <Td fontWeight={"bold"} colSpan={2}>
                    Previous Intitute
                  </Td>
                </Tr>

                {studentInfo &&
                  studentInfo.previousInstitutions.map(
                    (previousInstitution, index) => {
                      return (
                        <Tr key={index}>
                          <Td>{`${previousInstitution.name} - ${previousInstitution.location}`}</Td>
                          <Td>{`${previousInstitution.duration} - ${previousInstitution.Course}`}</Td>
                        </Tr>
                      );
                    }
                  )}
              </>
            ) : (
              ""
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
}
