import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL
// var url = process.env.NODE_ENV === 'development' ? "https://server.etechcomputeracademy.com/" : "https://server.etechcomputeracademy.com/"

const getlocal = async () => {
  const localCheck = localStorage.getItem("userData");
  localCheck = JSON.parse(localCheck);
  if (localCheck === null) {
    console.log("its null please logout");
  } else {
    console.log("its not null");
    console.log(localCheck);
    return localCheck;
  }
};

const send_post = async (url, body = {}) => {
  // const localCheck = await secureLocalStorage.getItem("userData");
  var localCheck = localStorage.getItem("userData");
  localCheck = JSON.parse(localCheck);
  // console.log(localCheck)
  var userId;
  var token;

  if (localCheck === null) {
    // console.log("its null please logout")
  } else {
    var { userId, token } = localCheck;
  }

  return axios.post(url, body, {
    headers: {
      userId: userId,
      Authorization: token,
      // id: storage.getString("userdata.userid"),
      // Authorization: storage.getString("userdata.token"),
    },
  });
};

const send_put = async (url, body = {}) => {
  const localCheck = localStorage.getItem("userData");
  localCheck = JSON.parse(localCheck);
  var userId;
  var token;
  if (localCheck === null) {
    // console.log("its null please logout")
  } else {
    var { userId, token } = localCheck;
  }
  return axios.put(url, body, {
    headers: {
      userId: userId,
      Authorization: token,
    },
  });
};

const send_get = async (url) => {
  var localCheck = localStorage.getItem("userData");
  localCheck = JSON.parse(localCheck);

  var userId;
  var token;

  if (localCheck === null) {
    // console.log("its null please logout")
  } else {
    var { userId, token } = localCheck;
  }
  return axios.get(url, {
    headers: {
      userId: userId,
      Authorization: token,
    },
  });
};

const send_delete = async (url) => {
  var localCheck = localStorage.getItem("userData");
  localCheck = JSON.parse(localCheck);

  var userId;
  var token;

  if (localCheck === null) {
    // console.log("its null please logout")
  } else {
    var { userId, token } = localCheck;
  }
  return axios.delete(url, {
    headers: {
      userId: userId,
      Authorization: token,
    },
  });
};

const logout = () => {
  console.log("logout");
  localStorage.clear();
};

export { send_post, send_get, send_put, send_delete, logout };
// , whatsapp_post , whatsapp_get , whatsapp_delete , whatsapp_put
