import React, { useState, useEffect, useRef } from "react";
import { SmallAddIcon, DeleteIcon } from "@chakra-ui/icons";
import { useDisclosure } from "@chakra-ui/react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  Text,
  Select,
  Input,
  Textarea,
  Box,
  Checkbox,
  Grid,
  GridItem,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import jsPDF from "jspdf";
import { useForm } from "react-hook-form";
import { AdminApis } from "../utils/Apis/Apis";
import {
  send_delete,
  send_get,
  send_post,
} from "../utils/hooks/AxiosHooks";
import { useParams } from "react-router-dom";

export default function Addfee() {
  const [size, setSize] = useState("");
  const [submissions, setSubmissions] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();
  const btnRef = useRef();
  const toast = useToast();
  const { register, handleSubmit, reset } = useForm();
  const { id } = useParams();
  const cancelRef = useRef();

  const fetchFeeStudents = async () => {
    try {
      const response = await send_get(
        `${AdminApis.getFeesDetails}/${id}`
      );
      setSubmissions(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.error(error);
      toast({
        title: "Error fetching data",
        description: "Unable to fetch fee details",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchFeeStudents();
  }, [id]);

  const handleClick = (newSize) => {
    setSize(newSize);
    onOpen();
  };

  const generatePDF = (data) => {
    const doc = new jsPDF();
    doc.text("Payment Invoice!", 10, 10);
    doc.text(`Invoice No.: ${data.Invoice}`, 10, 40);
    doc.text(`Name and Surname: ${data.Name}`, 10, 50);
    doc.text(`Phone Number: ${data.phone}`, 10, 60);
    doc.text(`Email: ${data.email}`, 10, 70);
    doc.text(`Address: ${data.address}`, 10, 80);
    doc.text(`Payment Date: ${data.date}`, 10, 100);
    doc.text(`Amount: ${data.amount}`, 10, 90);
    doc.text(`Who gave the money: ${data.GiveMoney}`, 10, 20);
    doc.text(`Who received the money: ${data.Receive}`, 10, 30);
    doc.text(`Description: ${data.Description}`, 10, 110);
    doc.save("Codex Solutions.pdf");
  };

  const onSubmit = async (data) => {
    try {
      data.admission_id = id;
      var response = await send_post(
        `${AdminApis.addStudentFee}`,
        data
      );
      console.log(response);
      setSubmissions((presubmissions) => [...presubmissions, data]);
      fetchFeeStudents();
      // generatePDF(data);
      toast({
        title: "PDF Generated Successfully",
        duration: 2000,
        status: "success",
        isClosable: true,
      });

      onClose();
    } catch (err) {
      console.error("Cannot get data", err);
      toast({
        title: "Error",
        description: "Unable to save data",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
    reset();
  };

  const handleDelete = async () => {
    try {
      await send_delete(
        `${AdminApis.deleteStudentFeeSection}/${deleteId}`
      );
      setSubmissions((presubmissions) =>
        presubmissions.filter((sub) => sub._id !== deleteId)
      );
      toast({
        title: "Deleted Successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      onAlertClose();
    } catch (error) {
      console.error("Cannot delete data", error);
      toast({
        title: "Error",
        description: "Unable to delete data",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const confirmDelete = (id) => {
    setDeleteId(id);
    onAlertOpen();
  };

  return (
    <>
      <Box style={{ position: "relative", margin: 50 }}>
        <Button
          style={{
            right: 80,
            bottom: 80,
            borderRadius: 50,
            height: 50,
            width: 50,
            position: "fixed",
          }}
          ref={btnRef}
          colorScheme="teal"
          onClick={() => handleClick("md")}
        >
          <SmallAddIcon style={{ fontSize: 30 }} />
        </Button>
        <TableContainer
          style={{ display: "flex", alignItems: "center" }}
        >
          <Table variant="striped">
            <Thead style={{ background: "grey", height: "60px" }}>
              <Tr style={{ color: "white" }}>
                <Th style={{ color: "white" }}>Payment_Amount</Th>
                <Th style={{ color: "white" }}>Payment_Mode</Th>
                <Th style={{ color: "white" }}>Remark</Th>
                <Th style={{ color: "white" }}>delete</Th>
              </Tr>
            </Thead>
            <Tbody>
              {submissions.map((item, index) => (
                <Tr key={index}>
                  <Td>{item.PaymentAmount}</Td>
                  <Td>{item.PaymentMode}</Td>
                  <Td>{item.description}</Td>
                  <Td>
                    <Button
                      onClick={() => confirmDelete(item._id)}
                      style={{ background: "red" }}
                    >
                      <DeleteIcon />
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      <Drawer onClose={onClose} isOpen={isOpen} size={size}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Text fontWeight={600} fontSize="24px">
              Fee Invoice
            </Text>
          </DrawerHeader>
          <DrawerBody
            css={{ "&::-webkit-scrollbar": { display: "none" } }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={2}
                gridAutoFlow="dense"
              >
                <GridItem width="100%" display="grid" gap="10px">
                  <Input
                    {...register("PaymentAmount", { required: true })}
                    variant="outline"
                    placeholder="Payment_Amount"
                  />
                </GridItem>
                <GridItem width="100%" display="grid" gap="10px">
                  <Select
                    {...register("PaymentMode", { required: true })}
                    placeholder="Payment_Mode"
                  >
                    <option value="Cash">Cash</option>
                    <option value="Phone_Pay">Phone_Pay</option>
                    <option value="G-pay">G-pay</option>
                  </Select>
                </GridItem>
              </Grid>
              <GridItem mt={5}>
                <Textarea
                  placeholder="description"
                  size="sm"
                  {...register("description", { required: true })}
                />
              </GridItem>
              <DrawerFooter>
                <Button type="submit" colorScheme="blue">
                  Save
                </Button>
              </DrawerFooter>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Payment
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onAlertClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
