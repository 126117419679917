import React , { ReactNode } from 'react';
import {
  Box,
  Flex,
  Avatar,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  Heading,
  Container,
  Text,
  Icon,
  createIcon,
  Image,
  IconButton,
  IconProps,
  chakra,
  SimpleGrid,
  VisuallyHidden,
  Input,
  StackDivider
} from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import darklogo from "../../Assets/Images/logo/logo2.png";
import lightlogo from "../../Assets/Images/logo/darklogo.png"



export default function Header() {
    const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex h={16} p={2} mt={5} alignItems={'center'} justifyContent={'space-between'}>
    <Box>
        {/* <Image src={ require("../../Assets/Images/logo/logo2.png") } h="45" /> */}
        <Image src={colorMode === 'light' ?darklogo : lightlogo } h="45" />
        {/* {colorMode} */}
    </Box>

    <Flex alignItems={'center'}>
      <Stack direction={'row'} spacing={7}>
        <Button onClick={toggleColorMode}>
          {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
        </Button>

        {/* <Menu>
          <MenuButton
            as={Button}
            rounded={'full'}
            variant={'link'}
            cursor={'pointer'}
            minW={0}>
            <Avatar
              size={'sm'}
              src={'https://avatars.dicebear.com/api/male/username.svg'}
            />
          </MenuButton>
          <MenuList alignItems={'center'}>
            <br />
            <Center>
              <Avatar
                size={'2xl'}
                src={'https://avatars.dicebear.com/api/male/username.svg'}
              />
            </Center>
            <br />
            <Center>
              <p>Username</p>
            </Center>
            <br />
            <MenuDivider />
            <MenuItem>Your Servers</MenuItem>
            <MenuItem>Account Settings</MenuItem>
            <MenuItem>Logout</MenuItem>
          </MenuList>
        </Menu> */}
      </Stack>
    </Flex>
  </Flex>
  )
}
