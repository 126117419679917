import React, { useReducer, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  json,
} from "react-router-dom";
import Login from "./Auth/Login";
import NotFound from "./Mainsite/NotFound";
import Home from "./Mainsite/Home";
import About from "./Mainsite/About";
import Dashboard from "./Admin/Dashboard";
import {
  ChakraBaseProvider,
  Heading,
  extendBaseTheme,
  Box,
  HStack,
} from "@chakra-ui/react";
import chakraTheme from "@chakra-ui/theme";
import AdminDashboard from "./Admin/parts/AdminDashboard";
import SoftwareWrapper from "./Admin/parts/AdminDashboard";
import StudentsList from "./Admin/StudentsList";
import Leads from "./Admin/Leads";
import Courses from "./Admin/Courses";
import Batches from "./Admin/Batches";
import Settings from "./Admin/Settings";
import StudentDetails from "./Admin/StudentsList/StudentDetails";
import FollowUp from "./Admin/Leads/FollowUp";
// import { motion } from 'framer-motion';
// import secureLocalStorage from "react-secure-storage";
import { AuthContext } from "./utils/contexts/AuthContext";
import { send_get, send_post } from "./utils/hooks/AxiosHooks";
import { AdminApis, logsApi } from "./utils/Apis/Apis";
import StudentLogin from "./Auth/StudentLogin";
import StudentPortal from "./StudentPortal/StudentDetails";
import VerifyCertificate from "./Certificate/VerifyCertificate";
import ComingSoon from "./Mainsite/ComingSoon";
import DoAdmisson from "./Admin/DoAdmission";
import Addfee from "./Admin/AddFee";
function App() {
  // const navigate = useNavigate();

  const spinnerVariants = {
    start: {
      rotate: 0,
    },
    end: {
      rotate: 360,
    },
  };

  var url = process.env.REACT_APP_API_URL
  // var url = process.env.NODE_ENV === 'development' ? "https://server.etechcomputeracademy.com/" : "https://server.etechcomputeracademy.com/"

  const spinnerTransition = {
    duration: 1.2,
    loop: Infinity,
    ease: "linear",
  };

  const initialstates = {
    auth_status: "loading",
    userMode: "None",
    // socketConnection: "http://localhost:8000/",
    // Local_ip_url: "http://localhost:8000/",
    socketConnection: url,
    Local_ip_url: url,
    userId: "",
    CoursesList: [],
    Batcheslist: [],
    // CoursesList:[],
    CertificatesList: [],
    eventslist: [],
    institutesList: [],
    nameTitles: [],
    otherRefrers: [],
    qualifications: [],
    StudentsList: [],
    leadsList: [],
  };

  const appReducer = (prevState, action) => {
    // console.log("prevState",prevState)
    // console.log("action",action)

    switch (action.type) {
      // case "TOGGLE_LANGUAGE": { return { ...prevState, language: action.language } };
      // case "TOGGLE_THEME": { return { ...prevState, colorScheme: action.theme } };
      // case "TOGGLE_UserMode": { return { ...prevState, userMode: action.userMode } };
      case "AuthState": {
        return {
          ...prevState,
          auth_status: action.loggedIn,
          userId: action.userId,
          userMode: action.userType,
        };
      }
      case "Get_Events": {
        return { ...prevState, eventslist: action.list };
      }
      case "Get_Institutions": {
        return { ...prevState, institutesList: action.list };
      }
      case "Get_Students": {
        return { ...prevState, institutesList: action.list };
      }
      case "savedata": {
        return { ...prevState, [action.listType]: action.data };
      }
    }
  };

  const AppFunctions = React.useMemo(
    () => ({
      getData: (data) => {
        send_get(data.api)
          .then((res) => {
            var response = res.data;
            dispatch({
              type: "savedata",
              listType: data.listType,
              data: response.data,
            });
          })
          .catch((err) => {
            send_post(logsApi.senderror, {
              error: err,
            });
          });
      },

      getEventsList: () => {
        send_get(AdminApis.getEvents)
          .then((res) => {
            var response = res.data;
            dispatch({ type: "Get_Events", list: response.data });
          })
          .catch((err) => {
            send_post(logsApi.senderror, {
              error: err,
            });
          });
      },

      getInstitutes_list: () => {
        send_get(AdminApis.getInstitutions)
          .then((res) => {
            var response = res.data;
            dispatch({
              type: "Get_Institutions",
              list: response.data,
            });
          })
          .catch((err) => {
            send_post(logsApi.senderror, {
              error: err,
            });
          });
      },

      getNameTitles_list: () => {
        send_get(AdminApis.getNameTitles)
          .then((res) => {
            var response = res.data;
            dispatch({ type: "Get_NameTitles", list: response.data });
          })
          .catch((err) => {
            send_post(logsApi.senderror, {
              error: err,
            });
          });
      },

      getOtherRefrers_list: () => {
        send_get(AdminApis.getOtherRefrers)
          .then((res) => {
            var response = res.data;
            dispatch({
              type: "Get_OtherRefrers",
              list: response.data,
            });
          })
          .catch((err) => {
            send_post(logsApi.senderror, {
              error: err,
            });
          });
      },

      getQualifications_list: () => {
        send_get(AdminApis.getQualifications)
          .then((res) => {
            var response = res.data;
            dispatch({
              type: "Get_Qualifications",
              list: response.data,
            });
          })
          .catch((err) => {
            send_post(logsApi.senderror, {
              error: err,
            });
          });
      },

      getStudents_list: () => {
        send_get(AdminApis.getStudents)
          .then((res) => {
            var response = res.data;
            console.log("getStudents_list : ", response);
            dispatch({ type: "Get_Students", list: response.data });
          })
          .catch((err) => {
            send_post(logsApi.senderror, {
              error: err,
            });
          });
      },

      getLeads_list: () => {
        send_get(AdminApis.getLeads)
          .then((res) => {
            var response = res.data;
            dispatch({ type: "Get_Leads", list: response.data });
          })
          .catch((err) => {
            send_post(logsApi.senderror, {
              error: err,
            });
          });
      },

      toggleUserMode: (Change_to) => {
        // console.log("Change_to_userMode",Change_to)
        // storage.set('userMode', Change_to)
        dispatch({ type: "TOGGLE_UserMode", userMode: Change_to });
      },

      loginSuccess: (data) => {
        localStorage.setItem("userType", "Admin");

        // secureLocalStorage.setItem("userType", "Admin");
        dispatch({
          type: "AuthState",
          loggedIn: true,
          userId: data.data.userId,
          userType: "Admin",
        });
      },

      StudentloginSuccess: (data) => {
        localStorage.setItem("userType", "Student");
        // secureLocalStorage.setItem("userType", "Student");
        dispatch({
          type: "AuthState",
          loggedIn: true,
          userId: data.data.userId,
          userType: "Student",
        });
      },
      checklocalId: () => {
        // console.log("success data", storage.getNumber("userdata.userid"))
      },

      Logout: () => {
        // secureLocalStorage.clear()
        localStorage.clear();
        // navigate("/")
        window.location.assign("/");
        dispatch({ type: "AuthState", loggedIn: false });
      },
    }),
    []
  );

  const [appStates, dispatch] = useReducer(appReducer, initialstates);

  // console.log(appStates)

  useEffect(() => {
    setTimeout(async () => {
      // Steps :
      // Step 1 : Check Credentials locally if found verify from server than proceed
      // const localCheck = await secureLocalStorage.getItem("userData")
      var localCheck = localStorage.getItem("userData");
      localCheck = JSON.parse(localCheck);
      // console.log(localCheck)

      if (!localCheck) {
        // console.log("localCheck is null")
        dispatch({ type: "AuthState", loggedIn: false });
      } else {
        // check by server

        // var userMode = await secureLocalStorage.getItem("userType")
        var userMode = localStorage.getItem("userType");

        dispatch({
          type: "AuthState",
          loggedIn: true,
          userId: localCheck.userId,
          userType: userMode,
        });
      }
      // console.log(appStates)
    }, 1000);
  }, []);

  if (appStates.auth_status == "loading") {
    return (
      <HStack minH={"100vh"} justifyContent={"center"} bg="gray.800">
        <Heading color={"#fff"}>Loading...</Heading>
      </HStack>
    );
  }

  return (
    <div className="App">
   

      <AuthContext.Provider value={{ appStates, AppFunctions }}>
        <Router>
          <Routes>
            {appStates.auth_status == false ? (
              <>
                {/* <Route path="/" element={<Home />} /> */}
                <Route path="/" element={<ComingSoon />} />
                <Route path="/login" element={<Login />} />
                <Route path="/student" element={<StudentLogin />} />
                <Route path="/myPortal" element={<StudentLogin />} />
                <Route
                  path="/verify"
                  element={<VerifyCertificate />}
                />
                <Route
                  path="/verify/:certificateno"
                  element={<VerifyCertificate />}
                />

                <Route path="*" element={<Login />} />
                {/* <Route path="*" element={<NotFound />} /> */}
              </>
            ) : appStates.auth_status == true &&
              appStates.userMode == "Admin" ? (
              <>
                <Route
                  path="/"
                  element={<SoftwareWrapper Component={Dashboard} />}
                />
                <Route
                  path="/dashboard"
                  element={<SoftwareWrapper Component={Dashboard} />}
                />
                <Route
                  path="/students"
                  element={
                    <SoftwareWrapper Component={StudentsList} />
                  }
                />
                <Route
                  path="/studentDetails/:studentid"
                  element={<StudentDetails />}
                />
                <Route
                  path="/students/admission/:admissionid"
                  element={<DoAdmisson />}
                />
                <Route path="/StudentFee/:id" element={<Addfee />} />
                <Route
                  path="/leads/"
                  element={<SoftwareWrapper Component={Leads} />}
                />
                <Route
                  path="/leads/FollowUp/:id"
                  element={<FollowUp />}
                />
                <Route
                  path="/Home"
                  element={
                    <SoftwareWrapper Component={StudentsList} />
                  }
                />
                <Route
                  path="/courses"
                  element={<SoftwareWrapper Component={Courses} />}
                />
                <Route
                  path="/Batches"
                  element={<SoftwareWrapper Component={Batches} />}
                />

                <Route
                  path="/settings"
                  element={<SoftwareWrapper Component={Settings} />}
                />
              </>
            ) : appStates.auth_status == true &&
              appStates.userMode == "Student" ? (
              <>
                <Route path="/" element={<StudentPortal />} />
                <Route path="*" element={<StudentPortal />} />
                <Route path="/myPortal" element={<StudentPortal />} />
              </>
            ) : (
              <>
                {/* <Route path="/" element={<ComingSoon />} />
                <Route path="/verify" element={<VerifyCertificate />} /> */}
                <Route path="login" element={<Login />} />

                <Route path="*" element={<NotFound />} />
                <Route path="/myPortal" element={<StudentLogin />} />
              </>
            )}
          </Routes>
        </Router>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
