import React, { useState } from "react";
import { FormControl, FormLabel } from "@chakra-ui/react";
import Gaurdians from "../../Admin/Leads/Gardians";

import { Input } from "@chakra-ui/react";
import { Select } from "@chakra-ui/react";
import { Grid } from "@chakra-ui/react";
import { GridItem } from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/react";
import { Checkbox } from "@chakra-ui/react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import {
  Box,
  Text,
  HStack,
  IconButton,
  Heading,
  Badge,
  VStack,
  useColorModeValue,
  useToast,
  Avatar,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { send_post } from "../hooks/AxiosHooks";
import { AdminApis, logsApi } from "../Apis/Apis";
import { AuthContext } from "../contexts/AuthContext";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from "@chakra-ui/react";
import jsPDF from "jspdf";
import { useForm } from "react-hook-form";

// const HandleClick = (newSize) => {
//   const [size, setSize] = useState("");
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   setSize(newSize);
//   onOpen();
// };

const sizes = ["lg"];
// https

// Add Fee
const obj = {
  Invoice: "",
  Name: "",
  PhoneNumber: "",
  Email: "",
  Adress: "",
  GiveMoney: "",
  ReceiveMoney: "",
  Amount: "",
  PaymentDate: "",
  Description: "",
};
export const studentColumns = [
  {
    Header: "Profile Picture",
    // accessor: 'profile_pic',
    Cell: ({ row }) => {
      // console.log("orignal", row.original)

      return (
        <Link to={`/studentDetails/${row.original._id}`}>
          <Avatar
            size="sm"
            bg={"purple.300"}
            src={row.original.student_Image}
            alt="Profile"
          />
        </Link>
      );
    },
  },
  // { Header: 'Full Name', accessor: ({ first_name, last_name }) => `${first_name} ${last_name}` },
  { Header: "Full Name", accessor: "student_name" },
  { Header: "Mobile No.", accessor: "mobile_no" },
  { Header: "Email", accessor: "student_emailId" },
  { Header: "Gender", accessor: "gender" },
  //   {
  //     Header: "Action",
  //     Cell: ({ row }) => {
  //       const [size, setSize] = useState("");

  //       const handleClick = (newSize) => {
  //         const { IsOpen, onOpen, onClose } = useDisclosure();
  //         setSize(newSize);
  //         onOpen();
  //       };

  //       const sizes = ["lg"];
  //     },
  //   },

  // { Header: 'Qualification', accessor: 'qualifications' },
  // { Header: 'Course Duration', accessor: ({ course_duration, course_duration_2 }) => `${course_duration} ${course_duration_2}` },
  {
    Header: "Actions",
    Cell: ({ row }) => {
      const { isOpen, onOpen, onClose } = useDisclosure();

      const cancelRef = React.useRef();
      const { AppFunctions, appStates } =
        React.useContext(AuthContext);

      const deletstudent = (studentId) => {
        send_post(AdminApis.deleteStudent, {
          id: studentId,
        })
          .then((res) => {
            var response = res.data;
            if (res.status === 200) {
              // reset()s
              onClose();
              AppFunctions.getData({
                listType: "StudentsList",
                api: AdminApis.getStudents,
              });
            }
          })
          .catch(() => {});
      };
      // formData

      // ----------------------------------------------------------------
      return (
        <HStack>
          <Menu>
            <MenuButton
              as={IconButton}
              colorScheme="green"
              aria-label="Edit"
              icon={<FiEdit />}
              //  rightIcon={<ChevronDownIcon />}
            ></MenuButton>
            <MenuList bg={"green"}>
              <Link to={`studentDetails/${row.original._id}`}>
                <MenuItem>Edit</MenuItem>
              </Link>
              <Link to={`/students/admission/${row.original._id}`}>
                <MenuItem>Admission</MenuItem>
              </Link>
              <Link to={`/StudentFee/${row.original._id}`}>
                <MenuItem>Add Fee</MenuItem>
              </Link>
            </MenuList>
          </Menu>
          {/* <Link to={`/edit/${row.original._id}`}> */}
          {/* <IconButton
                    colorScheme='green'
                    aria-label="Edit"
                    icon={<FiEdit />}
                    onClick={() => {
                        // Handle edit action
                        console.log(row.original)
                        // navigate(`/edit/${row.original.id}`)
                    }}
                /> */}
          {/* </Link> */}

          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Delete Student
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure? You can't undo this action afterwards.
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    colorScheme="red"
                    onClick={() => deletstudent(row.original._id)}
                    ml={3}
                  >
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
          <IconButton
            colorScheme="red"
            aria-label="Delete"
            icon={<FiTrash2 />}
            onClick={onOpen}
          />
        </HStack>
      );
    },
  },
];

// Add Leads code

export const LeadstudentColumns = [
  {
    Header: "Profile Picture",
    // accessor: 'profile_pic',
    Cell: ({ row }) => {
      // console.log("orignal", row.original)

      return (
        // <Link to={`/LeadDetails/${row.original._id}`}>
        <Avatar
          size="sm"
          bg={"purple.300"}
          src={row.original.student_Image}
          alt="Profile"
        />
        // </Link>
      );
    },
  },
  // { Header: 'Full Name', accessor: ({ first_name, last_name }) => `${first_name} ${last_name}` },
  { Header: "Full Name", accessor: "student_name" },
  { Header: "Mobile No.", accessor: "mobile_no" },
  { Header: "Email", accessor: "student_emailId" },
  { Header: "Gender", accessor: "gender" },

  //   {
  //     Header: "Action",
  //     Cell: ({ row }) => {
  //       const [size, setSize] = useState("");

  //       const handleClick = (newSize) => {
  //         const { IsOpen, onOpen, onClose } = useDisclosure();
  //         setSize(newSize);
  //         onOpen();
  //       };

  //       const sizes = ["lg"];
  //     },
  //   },

  // { Header: 'Qualification', accessor: 'qualifications' },
  // { Header: 'Course Duration', accessor: ({ course_duration, course_duration_2 }) => `${course_duration} ${course_duration_2}` },
  {
    Header: "Actions",
    Cell: ({ row }) => {
      const cancelRef = React.useRef();
      const { AppFunctions, appStates } =
        React.useContext(AuthContext);

      const deleteLead = (LeadstudentId) => {
        console.log(LeadstudentId);
        onClose();
        send_post(AdminApis.deleteLead, {
          id: LeadstudentId,
        })
          .then((res) => {
            var response = res.data;
            if (res.status === 200) {
              onClose();
              AppFunctions.getData({
                listType: "leadsList",
                api: AdminApis.getLeads,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      };
      const { isOpen, onOpen, onClose } = useDisclosure();
      // const {
      //   isOpen: thisOpen,
      //   onOpen: thatOpen,
      //   onClose: thisClose,
      // } = useDisclosure();

      return (
        <HStack>
          <Menu>
            <MenuButton
              as={IconButton}
              colorScheme="green"
              aria-label="Edit"
              icon={<FiEdit />}
              //  rightIcon={<ChevronDownIcon />}
            ></MenuButton>
            <MenuList bg={"green"}>
              <Link to={`/leads/FollowUp/${row.original._id}`}>
                <MenuItem>Follow Up</MenuItem>
              </Link>
              <Link to={`/students`}>
                <MenuItem>Do Admission</MenuItem>
              </Link>
            </MenuList>
          </Menu>
          {/* <Link to={`/edit/${row.original._id}`}> */}
          {/* <IconButton
                    colorScheme='green'
                    aria-label="Edit"
                    icon={<FiEdit />}
                    onClick={() => {
                        // Handle edit action
                        console.log(row.original)
                        // navigate(`/edit/${row.original.id}`)
                    }}
                /> */}
          {/* </Link> */}

          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Delete leads
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure? You can't undo this action afterwards.
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    colorScheme="red"
                    onClick={() => deleteLead(row.original._id)}
                    ml={3}
                  >
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
          <IconButton
            colorScheme="red"
            aria-label="Delete"
            icon={<FiTrash2 />}
            onClick={onOpen}
          />
        </HStack>
      );
    },
  },
];

// Existing code...

export const coursesColumns = [
  {
    Header: "Course Name",
    accessor: "course",
  },
  {
    Header: "Short Name",
    accessor: "shortName",
  },
  {
    Header: "Duration",
    accessor: "duration",
  },
  {
    Header: "Fee",
    accessor: "fee",
  },
  {
    Header: "Offer Fee",
    accessor: "offerFee",
  },
  {
    Header: "Cover Image",
    accessor: "coverImageUrl",
    Cell: ({ value }) => (
      <Image src={value} alt="Course cover image" boxSize="50px" />
    ),
  },
  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ row }) => {
      // Handle Edit
      const handleCourseEdit = (courseData) => {
        // Implement edit functionality here
      };

      // Handle Delete
      const handleCourseDelete = (courseId) => {
        // Implement delete functionality here
      };
      return (
        <>
          <Button onClick={() => handleCourseEdit(row.original._id)}>
            Edit
          </Button>
          <Button
            onClick={() => handleCourseDelete(row.original._id)}
            colorScheme="red"
            ml={2}
          >
            Delete
          </Button>
        </>
      );
    },
  },
];
