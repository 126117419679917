import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Tooltip } from "@chakra-ui/react";
import { send_get } from "../../../utils/hooks/AxiosHooks";
import { AdminApis } from "../../../utils/Apis/Apis";
import { useParams } from "react-router-dom";
import { AiOutlineConsoleSql } from "react-icons/ai";

export default function StudentInfo() {
  const [studentData, setStudentData] = useState([]);
  const { studentid } = useParams();
  const fetchStudent = async () => {
    try {
      const res = await send_get(
        `${AdminApis.getStudentDetails}/${studentid}`
      );
      // console.log(res.data.data);
      setStudentData(res.data.data);
    } catch (err) {
      console.log(err, "req cannot be found");
    }
  };

  useEffect(() => {
    fetchStudent();
  }, []);
  return (
    <div>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Td fontWeight={"bold"} colSpan={2}>
                Student Info
              </Td>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Aadhar Card no</Td>
              {studentData.map((data) => (
                <Td>{data.aadhar_card_no}</Td>
              ))}
            </Tr>

            <Tr>
              <Td>Email Id</Td>
              {studentData.map((data) => (
                <Td>{data.student_emailId}</Td>
              ))}
            </Tr>

            <Tr>
              <Td>Date Of Birth</Td>
              {studentData.map((data) => (
                <Td>{data.student_dob}</Td>
              ))}
            </Tr>

            <Tr>
              <Td>Qualifications</Td>
              {studentData.map((data) => (
                <Td>{data.qualifications}</Td>
              ))}
            </Tr>

            <Tr>
              <Td fontWeight={"bold"} colSpan={2}>
                Gaurdians
              </Td>
            </Tr>

            <Tr>
              <Td>Mother Name </Td>
              {studentData.map((data) => (
                <Td>
                  {data.guardians.length > 0
                    ? data.guardians[0].name
                    : null}
                  __ (
                  {data.guardians.length > 0
                    ? data.guardians[0].mobile
                    : null}
                  )
                </Td>
              ))}
            </Tr>

            <Tr>
              <Td>Father Name </Td>
              {studentData.map((data) => (
                <Td>
                  {data.guardians.length > 1
                    ? data.guardians[1].name
                    : null}
                  __(
                  {data.guardians.length > 1
                    ? data.guardians[1].mobile
                    : null}
                  )
                </Td>
              ))}
            </Tr>

            <Tr>
              <Td fontWeight={"bold"} colSpan={2}>
                Previous Intitute
              </Td>
            </Tr>

            <Tr>
              {studentData.map((data) => (
                <Td>
                  {data.previousInstitutions.length > 0
                    ? data.previousInstitutions[0].name
                    : null}
                </Td>
              ))}

              <Td color="red">
                {studentData.map((data) => (
                  <Tooltip hasArrow label="Course Completed">
                    {data.previousInstitutions.length > 0
                      ? data.previousInstitutions[0].Course
                      : null}
                  </Tooltip>
                ))}
              </Td>
            </Tr>

            {/* <Tr>
              <Td>
                Vidual IAS Acadmy (Bhisan Shroop Colony Panipat )
              </Td>
              <Td color={"green"}>
                <Tooltip hasArrow label="Course Ongoaing">
                  CGL Coaching
                </Tooltip>
              </Td>
            </Tr> */}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
}
