import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  useColorModeValue,
  Text,
  Container,
  VStack,
  Card,
  Image,
  Stack,
  CardBody,
  Heading,
  CardFooter,
  Button,
  ButtonGroup,
  SimpleGrid,
} from "@chakra-ui/react";

import { ChevronDownIcon } from "@chakra-ui/icons";
import moment from "moment";
import { FiBook } from "react-icons/fi";
import { BiRupee } from "react-icons/bi";
import { TbCertificate } from "react-icons/tb";
// import { PiCertificate } from 'react-icons/pi';

export default function Admission({ admissionsList }) {
  return (
    <>
      {admissionsList && admissionsList.length > 0
        ? admissionsList.map((admission) => {
            var courseData = admission.courseData[0];
            return (
              <Card
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
              >
                <Image
                  objectFit="cover"
                  maxW={{ base: "100%", sm: "300px" }}
                  src={courseData.CoverImageUrl}
                  alt={courseData.Course}
                />

                <CardBody w="100%">
                  <SimpleGrid columns={2} w="100%">
                    <VStack>
                      <Heading pb="2" size="md">
                        {courseData.Course}
                      </Heading>

                      <Text>
                        <strong> Admission Date </strong> :
                        {moment(admission.admission_date).format(
                          "DD/MM/YYYY"
                        )}
                      </Text>
                      <Text>
                        <strong> Course Duration </strong> :
                        {admission.course_duration}
                      </Text>
                      <Text>
                        <strong> Course Status </strong> : complete
                      </Text>
                      <Text>
                        <strong> Fee Status </strong> : Paid
                      </Text>
                    </VStack>
                    <Stack direction={"column"}>
                      <Button
                        variant="solid"
                        leftIcon={<FiBook />}
                        colorScheme="facebook"
                      >
                        Check Syllabus
                      </Button>
                      <Button
                        variant="solid"
                        leftIcon={<BiRupee />}
                        colorScheme="whatsapp"
                      >
                        Pay Fee
                      </Button>
                      <Button
                        variant="solid"
                        leftIcon={<TbCertificate />}
                        colorScheme="yellow"
                      >
                        Certificate
                      </Button>
                    </Stack>
                  </SimpleGrid>
                </CardBody>
              </Card>
            );
          })
        : "No Admission Found"}
    </>
  );
}
