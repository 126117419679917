import {
  SimpleGrid,
  HStack,
  VStack,
  Button,
  Stack,
  Image,
  Badge,
  Divider,
  IconButton,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  FiPhone,
  FiPhoneCall,
  FiShare2,
  FiShoppingCart,
} from "react-icons/fi";
import { Card, CardBody, CardFooter } from "@chakra-ui/react";
import { send_get, send_post } from "../../utils/hooks/AxiosHooks";
import { AuthContext } from "../../utils/contexts/AuthContext";
import { AdminApis, logsApi } from "../../utils/Apis/Apis";

export default function AllCourses() {
  const { AppFunctions, appStates, theme } =
    React.useContext(AuthContext);

  const [courses, setCourses] = useState([]);

  const fetchCourses = () => {
    // Course/getCourses
    send_get(AdminApis.getCourses)
      .then((res) => {
        var response = res.data;
        setCourses(res.data.data);
        // console.log(response)
      })
      .catch((err) => {
        send_post(logsApi.senderror, {
          error: err,
        });
      });
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  return (
    <>
      <SimpleGrid columns={{ sm: 1, md: 4 }} spacing={4}>
        {courses.map((course, index) => {
          return (
            <Card maxW="sm" m="0" p="0" key={index}>
              <CardBody p="0">
                <Image
                  fit
                  src={course.CoverImageUrl}
                  alt={course.Course}
                  borderRadius="lg"
                  borderBottomLeftRadius={0}
                  borderBottomRightRadius={0}
                />
                <VStack justifyContent={"space-between"}>
                  <Text mt="2" fontWeight={"semibold"}>
                    {course.Course}
                  </Text>

                  <HStack m="2" w="100%">
                    <VStack
                      w="100%"
                      justifyContent={"flex-start"}
                      alignItems={"flex-start"}
                      mt={2}
                    >
                      <Badge
                        colorScheme="red"
                        textDecoration={"line-through"}
                      >
                        Fee : {course.fee}
                      </Badge>
                      <Badge colorScheme="green">
                        Offer : {course.offer_fee}
                      </Badge>
                    </VStack>

                    <HStack
                      w="100%"
                      justifyContent={"flex-end"}
                      alignItems={"flex-end"}
                    >
                      <IconButton
                        colorScheme="purple"
                        icon={<FiPhoneCall color="#fff" />}
                      />
                    </HStack>
                  </HStack>
                </VStack>
              </CardBody>
              <Divider />
              <CardFooter>
                <Stack
                  direction="column"
                  w="100%"
                  spacing={2}
                  align="center"
                >
                  <Button
                    variant={"solid"}
                    colorScheme="teal"
                    w="100%"
                    size={"sm"}
                    rightIcon={<FiShare2 />}
                  >
                    Refer A friend
                  </Button>
                  <Button
                    colorScheme="green"
                    size={"sm"}
                    w="100%"
                    rightIcon={<FiPhone />}
                  >
                    Enroll Now
                  </Button>
                </Stack>
                {/* <ButtonGroup flexDirection={"column"} spacing={2} >
              </ButtonGroup> */}
              </CardFooter>
            </Card>
          );
        })}
      </SimpleGrid>
    </>
  );
}
