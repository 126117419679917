import { SimpleGrid, HStack, VStack, Button, Stack, Image, Badge, Divider, IconButton } from '@chakra-ui/react'
import React, {  } from 'react'
import { FiPhone, FiPhoneCall, FiShare2, FiShoppingCart } from 'react-icons/fi'
import { Card, CardBody, CardFooter } from '@chakra-ui/react'




export default function AllCourses() {
  return (
    <>
   
    <SimpleGrid columns={{ sm: 1, md: 4 }} spacing={4}>

    {
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => {
        return (



          <Card maxW='sm' m="0" p="0" key={index} >
            <CardBody p="0" >

              <Image
              fit
                src={
                  index % 2 === 0 ?
                    "https://courses.wscubetech.com/s/store/courses/62eb785e0cf206fb88fdab25/cover.jpg?v=1"
                    : "https://www.upshottechnologies.in/images/course/web-development-training.jpg"
                }
                alt='Green double couch with wooden legs'
                borderRadius='lg'
                
                borderBottomLeftRadius={0}
                borderBottomRightRadius={0}
              />

              <HStack m="2"   >

                <VStack w="100%" justifyContent={"flex-start"} alignItems={"flex-start"} mt={2} >
                  <Badge colorScheme="red" textDecoration={"line-through"} > Fee : 40,000</Badge>
                  <Badge colorScheme="green"  >Offer : 20,000</Badge>
                </VStack>

                <HStack w="100%" justifyContent={"flex-end"} alignItems={"flex-end"} >
                  <IconButton
                    colorScheme="purple"
                    icon={<FiPhoneCall color='#fff' />}
                  />
                </HStack>

              </HStack>

            </CardBody>
            <Divider />
            <CardFooter>
              <Stack direction='column' w="100%" spacing={2} align='center'>
                <Button variant={"solid"} colorScheme="teal" w="100%" size={"sm"} rightIcon={<FiShare2 />} >  Refer A friend </Button>
                <Button colorScheme="green" size={"sm"} w="100%" rightIcon={<FiPhone />} >  Enroll Now </Button>
              </Stack>
              {/* <ButtonGroup flexDirection={"column"} spacing={2} >
              </ButtonGroup> */}
            </CardFooter>

          </Card>

        )
      })
    }

  </SimpleGrid>
  </>
  )
}
