import {
  SimpleGrid,
  Box,
  Avatar,
  HStack,
  Heading,
  VStack,
  Text,
  Button,
  Stack,
  Image,
  Badge,
  Divider,
  ButtonGroup,
  IconButton,
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import {
  FiHeart,
  FiLogOut,
  FiMail,
  FiPhone,
  FiSend,
  FiShare,
  FiShare2,
  FiShoppingCart,
  FiThumbsUp,
} from "react-icons/fi";
import { BiArrowBack, BiRupee, BiUserPin } from "react-icons/bi";
import { TbCertificate } from "react-icons/tb";
import { AiOutlineUserAdd } from "react-icons/ai";
import {
  IoBookOutline,
  IoNotificationsCircleOutline,
} from "react-icons/io5";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "@chakra-ui/react";
import bgCover from "../../Assets/Images/backgrounds/bg2-cover-large.jpeg";
import { Grid, GridItem } from "@chakra-ui/react";
import {
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import AllCourses from "./StudentSections/AllCourses";
import StudentInfo from "./StudentSections/StudentInfo";
import Attendence from "./StudentSections/Attendence";
import "react-calendar/dist/Calendar.css";
import { send_get } from "../../utils/hooks/AxiosHooks";
import { AdminApis } from "../../utils/Apis/Apis";
import { useParams } from "react-router-dom";
//is open and close

export default function StudentDetails() {
  const [height, setHeight] = useState(0);
  const [mheight, setMheight] = useState(0);

  const [mainpageheight, setMainpageheight] = useState(0);
  const [topnavheight, setTopnavheight] = useState(0);
  const [floatingcardheight, setFloatingcardheight] = useState(0);
  const [floatingbuttonheight, setFloatingbuttonheight] = useState(0);

  const ref = useRef(null);
  const maindiv = useRef(null);
  const topnav = useRef(null);
  const floatingcard = useRef(null);
  const floatingbutton = useRef(null);

  useEffect(() => {
    // setHeight(ref.current.clientHeight)
    // setMheight(maindiv.current.clientHeight)

    setMainpageheight(maindiv.current.clientHeight);
    setTopnavheight(topnav.current.clientHeight);
    setFloatingcardheight(floatingcard.current.clientHeight);
    setFloatingbuttonheight(floatingbutton.current.clientHeight);
  });

  const [selectedMenuItem, setSelectedMenuItem] =
    useState("Student Info");

  const handleMenuSelection = (item) => {
    console.log("handleMenuSelection", item);
    setSelectedMenuItem(item);
  };

  var sideMenu = [
    {
      name: "All Courses",
      icon: IoBookOutline,
    },
    {
      name: "Student Info",
      icon: BiUserPin,
    },
    {
      name: "Admission",
      icon: AiOutlineUserAdd,
    },
    {
      name: "Attendence",
      icon: BiUserPin,
    },
    {
      name: "Fee",
      icon: BiRupee,
    },
    {
      name: "Certificates",
      icon: TbCertificate,
    },
    {
      name: "Notifications",
      icon: IoNotificationsCircleOutline,
    },
  ];
  const { studentid, admissionid } = useParams();

  const [studentData, setStudentData] = useState([]);
  const getstudentData = async () => {
    try {
      const resp = await send_get(
        `${AdminApis.getStudentDetails}/${studentid}`
      );
      // console.log(resp.data.data);
      setStudentData(resp.data.data);
    } catch (err) {
      console.log(err, "request faileds");
    }
  };

  // const [Time, setTime] = useState([]);

  // const FetchTime = async () => {
  //   try {
  //     const resp = await send_get(`${AdminApis.getAdmission}`);
  //     console.log(resp.data.data);
  //     setTime(resp.data.data);
  //   } catch (err) {
  //     console.log(err, "request faileds");
  //   }
  // };

  // console.log(useParams());
  useEffect(() => {
    getstudentData();
    // FetchTime();
  }, []);

  return (
    // main div
    <Box ref={maindiv} minH={"100vh"} maxH={"100vh"}>
      {/* top nav bar  */}
      <SimpleGrid
        ref={topnav}
        bg="purple.200"
        bgImage={bgCover}
        style={{ backgroundSize: "cover" }}
        columns={{ sm: 1, md: 2 }}
        padding={5}
      >
        <Box style={{ position: "sticky", top: 0 }} bg={"#fffff06"}>
          <HStack spacing={5}>
            {studentData.map((data) => (
              <Avatar
                src={data.student_Image}
                size="xl"
                border={"2px solid"}
              />
            ))}

            <VStack
              justifyContent={"center"}
              alignItems={"flex-start"}
              h="100%"
            >
              {studentData.map((data) => (
                <Heading
                  bg={"#000000ad"}
                  p="2"
                  borderRadius={5}
                  size={"md"}
                  color={"#fff"}
                >
                  {data.student_name}
                </Heading>
              ))}
              {studentData.map((data) => (
                <Text
                  bg={"#000000ad"}
                  p="2"
                  borderRadius={5}
                  fontSize="sm"
                  color={"#fff"}
                >
                  {data.student_address}
                  <Text> {data.mobile_no} </Text>
                </Text>
              ))}
            </VStack>
          </HStack>
        </Box>

        <HStack justifyContent={"flex-end"} alignItems={"flex-end"}>
          <Button
            mt="2"
            bg="#fff"
            rightIcon={<FiSend />}
            variant={"solid"}
          >
            Edit Profile
          </Button>
        </HStack>
      </SimpleGrid>
      {/* top nav bar ends  */}

      {/* content grid  */}

      <Grid
        bg="gray.200"
        style={{
          height: `calc(${mainpageheight}px - ${topnavheight}px)`,
        }}
        templateColumns={{
          sm: "repeat(16, 1fr)",
          md: "repeat(16, 1fr)",
        }}
        overflow={"scroll"}
      >
        <GridItem
          colSpan={{ lg: 3, md: 16 }}
          style={{ overflow: "scroll" }}
          p={"4"}
          //  bg="#dcdad3"
        >
          <VStack
            bg={"#fff"}
            borderRadius={8}
            h={`calc(${mainpageheight}px - ${topnavheight}px - ${floatingcardheight}px - ${floatingbuttonheight}px - 60px )`}
          >
            <List w="100%">
              <ListItem>
                <Text
                  color={"green.600"}
                  textAlign={"center"}
                  fontWeight={"bold"}
                >
                  Class Timings
                </Text>
              </ListItem>
            </List>

            <List w={"100%"} px="3" overflow={"scroll"}>
              {[1, 2, 3, 4, 5].map((index) => {
                // console.log(index);
                return (
                  <HStack
                    shadow={"md"}
                    p={2}
                    bg={index % 2 == 0 ? "red.400" : "purple.400"}
                    borderRadius={8}
                    my="2"
                  >
                    <VStack w="100%">
                      <Text
                        fontWeight={"bold"}
                        fontSize={"small"}
                        color={"#fff"}
                      >
                        Spoken English
                      </Text>
                      <Text
                        style={{ justifyContent: "space-between" }}
                        color={"#fff"}
                        fontSize={"xx-small"}
                      >
                        <Badge colorScheme="purple">Mon</Badge>-
                        <Badge colorScheme="pink">Fri</Badge>
                      </Text>
                    </VStack>
                    {/* <Divider orientation='vertical' /> */}
                    <VStack w="100%">
                      <Badge colorScheme="green"> 9.00</Badge>
                      <Badge colorScheme="red">10:00 Am </Badge>
                    </VStack>
                  </HStack>
                );
              })}

              {/* <ListItem> <Text fontSize={"sm"} > Spoken English</Text> </ListItem>
              <ListItem> <Text fontSize={"sm"} > Basic Computer</Text> </ListItem>
              <ListItem> <Text fontSize={"sm"} > Web Designing</Text> </ListItem>
              <ListItem> <Text fontSize={"sm"} > Graphic Designing</Text> </ListItem>
              <ListItem> <Text fontSize={"sm"} > Front Development</Text> </ListItem>
              <ListItem> <Text fontSize={"sm"} > Backend Development</Text> </ListItem>
              <ListItem> <Text fontSize={"sm"} > FullStack Development</Text> </ListItem>
              <ListItem> <Text fontSize={"sm"} > Android Development</Text> </ListItem> */}
            </List>
          </VStack>

          <List
            spacing={3}
            ref={floatingcard}
            bg={"#ffff"}
            borderRadius={8}
            mt="3"
            style={{ position: "sticky", bottom: 50 }}
          >
            {sideMenu.map((item) => {
              return (
                <ListItem
                  p="1"
                  bg={
                    selectedMenuItem == item.name
                      ? "purple.500"
                      : null
                  }
                  color={
                    selectedMenuItem == item.name ? "#fff" : null
                  }
                  className="clickable"
                  onClick={() => handleMenuSelection(item.name)}
                >
                  <ListIcon
                    as={item.icon}
                    color={
                      selectedMenuItem == item.name
                        ? "#fff"
                        : "purple"
                    }
                  />
                  {item.name}
                </ListItem>
              );
            })}
          </List>

          <Button
            mt="3"
            ref={floatingbutton}
            colorScheme="green"
            width={"100%"}
            leftIcon={<FiLogOut />}
            style={{ position: "sticky", bottom: 5 }}
          >
            Go Back
          </Button>
        </GridItem>

        <GridItem colSpan={13} p={"4"} overflow={"scroll"}>
          <Box
            bg={"#fff"}
            p="3"
            px="5"
            h="100%"
            borderRadius={8}
            overflow={"scroll"}
          >
            {selectedMenuItem == "All Courses" ? (
              <AllCourses />
            ) : selectedMenuItem == "Student Info" ? (
              <StudentInfo />
            ) : selectedMenuItem == "Attendence" ? (
              <Attendence />
            ) : null}
          </Box>
        </GridItem>
      </Grid>

      {/* content grid end */}

      {/* main div end */}
    </Box>
  );
}
